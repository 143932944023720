<template>
	<v-list nav dense class="primary">

		<v-list-item v-for="(item, index) in computedTabs"
					 :key="index"
					 link
					 @click="go(item.path, item.name)"
					 active-class="darkgrey"
					 class="list-item">

			<v-list-item-icon class="white--text">
				<v-icon class="navicons white--text">{{ item.icon }}</v-icon>
			</v-list-item-icon>

			<v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>

		</v-list-item>

	</v-list>

</template>

<script>
import {mapGetters} from 'vuex'; // Map the Getters from the Vuex Store
export default {
	name: "MenuList",
	data: () => ({
		tabs: [
			// USERS
			// {name: 'Home', path: "/", icon: "icons8-home", userLevels: ['SA', 'CU']},
			{name: 'Dashboard', path: "/dashboard", icon: "icons8-content", userLevels: ['SA', 'SU', 'DA']},
            {name: 'Fire Roll Call', path: "/fireRollCall", icon: "icons8-fires", userLevels: ['SA', 'DA']},
			{name: 'Observations', path: "/observations", icon: "icons8-eye", userLevels: ['SA', 'SU', 'DA']},
			{name: 'Settings', path: "/settings", icon: "icons8-settings", userLevels: ['DA']},
            // {name: 'Questions', path: "/questions", icon: "icons8-ask-question", userLevels: ['SA', 'DA']},
            {name: 'Sites', path: "/sites", icon: "icons8-skyscrapers", userLevels: ['SA', 'DA']},
            {name: 'Staff', path: "/staff-directory", icon: "icons8-people", userLevels: ['SA', 'SU', 'DA']},
            {name: 'My Profile', path: "/userProfile", icon: "icons8-customer", userLevels: ['SA', 'SU', 'DA']},
            {name: 'My Schedule', path: "/userSchedule", icon: "icons8-schedule", userLevels: ['SA', 'SU', 'DA']},
            {name: 'IT Support', path: "/itSupport", icon: "icons8-computer-support", userLevels: ['SA', 'SU', 'DA']},
            // {name: 'Concerns', path: "/concerns", icon: "icons8-siren", userLevels: ['SA', 'SU', 'DA']},
		],
		langPath: 'menuItems'
	}),
	computed: {
		...mapGetters({
			GET_mobileNav: 'GET_mobileNav',
			GET_currentUser: 'GET_currentUser'
		}),

        computedTabs() {
			const t = this
			const tabs = t.tabs
			const newTabs = []

            // If the current user has access, display the link
            tabs.forEach(function (tab) {
                if (tab.userLevels.includes(t.GET_currentUser.userLevel)) {

                    // Only show Fire Roll Call to Admins and those with access
                    if (tab.name === 'Fire Roll Call') {
                        if (t.GET_currentUser.userLevel !== 'SA' && !t.GET_currentUser.userAccessAndAbilities.includes('Fire Marshall')) {
                            return
                        }
                    }

                    newTabs.push(tab)
                }
            })

			return newTabs
		}
	},

	methods: {
		// // updateBreadcrumbs(name, path) {
		// //     var t = this;
		// //     var breadcrumbs = [];
		// //     var uperrcaseName = t.MIX_capitalizeText(name);
		// //     breadcrumbs.push ({ name: uperrcaseName, path: path}) // Set the Main Path
		// //     t.MIX_breadcrumbs(breadcrumbs);
		// // },

		go(path, name) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err)
			})
			this.MIX_mobileNav(false)
			//// this.updateBreadcrumbs(name, path);
		},
	}
}
</script>

<style scoped>

.list-item {
	transition: 0.3s
}

.list-item:hover {
	background: var(--v-accent-base)
}

.navicons {
	font-size: 24px !important;
	font-weight: bold !important;
}

.v-tab--active {
	background-color: var(--v-primary-base) !important;
}
</style>
