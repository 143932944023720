<!--
SWAPP

When SWAPPing IN/OUT from a mobile device, this view will render the camera for the user to be able to scan a location's QR code.
If the user is either on a desktop (and allowed to SWAPP), or having trouble scanning the QR code,
they can select a reason, a site and a location, and SWAPP in manually.

If the User has not SWAPPed out from the previous location and is trying to SWAPP in to a different location, this
triggers the SWAPPing Issue dialog, SWAPPs the User out (of the old) and in (to the new), and also updates the User's schedule
so everything is kept in sync.
-->
<template>
    <div style="width: 100%">

        <!--Display QR Code Reader-->
        <div class="mt-n4" v-if="!isTroubleSwappingVisible && !showSwappMessage">

            <!-- QR code reader component -->
            <qrCodeReader v-if="$vuetify.breakpoint.width < 840"/>

            <!-- Instructions -->
            <div v-if="$vuetify.breakpoint.width < 840" class="ma-4">
                <app-text category="text-large" class="primary--text text-uppercase mb-4">
                    SWAPP {{ $route.params.direction }}
                </app-text>
                <app-text category="text-default" class="mb-2">1. Allow your camera when prompted</app-text>
                <app-text category="text-default" class="mb-2">2. Point your camera at the poster</app-text>
                <app-text category="text-default">3. Make sure the QR Code is visible in the box above</app-text>
            </div>

        </div>

        <!--Manual SWAPPing-->
        <div v-if="!showSwappMessage" class="ma-4">

            <!--Can't SWAPP In Button-->
            <v-btn v-if="$vuetify.breakpoint.width < 840" @click="isTroubleSwappingVisible = !isTroubleSwappingVisible"
                   color="white" depressed :height="buttonSizeDefault">
                {{ swappTypeMessage }}
            </v-btn>

            <!--Manual SWAPPING-->
            <v-col class="pa-0 mb-4" cols="12" xs="12" sm="8">

                <v-card
                    v-if="isTroubleSwappingVisible || ($vuetify.breakpoint.width >= 840 && GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop'))"
                    :class="$vuetify.breakpoint.width >= 840 && GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop') ? 'mt-8 pa-4' : 'mt-4 pa-4'"
                    class="rounded-lg" flat>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text">
                        {{ computedManualSwappTitle }}
                    </app-text>

                    <!--Instruction-->
                    <app-text category="text-default" class="mt-4">
                        {{ computedManualSwappInstructions }}
                    </app-text>

                    <!--Manual Swapp reasons-->
                    <v-select @change="handleSiteSelection"
                              background-color="white"
                              class="mt-4"
                              filled
                              hide-details
                              :items="manualSwappReasons"
                              label="Reason?"
                              required
                              v-model="manualSwappReason"/>

                    <!--Site selector-->
                    <v-select v-if="$route.params.direction.toUpperCase() !== 'OUT' && manualSwappReason"
                              @change="handleSiteSelection"
                              background-color="white"
                              class="mt-4"
                              filled
                              hide-details
                              :items="computedSitesData"
                              item-text="siteName"
                              label="Sites"
                              required
                              return-object
                              v-model="selectedSite"/>

                    <!--Site selector-->
                    <v-select v-if="locationsDataRenderable && locationsDataRenderable.length > 1"
                              class="mt-4"
                              background-color="white"
                              :error="hasLocationSelectionError"
                              filled
                              hide-details="auto"
                              :items="locationsDataRenderable"
                              item-text="locationName"
                              label="Locations"
                              required
                              return-object
                              v-model="selectedLocation"/>

                    <!--Site Name-->
                    <app-text v-if="selectedSite.siteName && selectedSite.siteName"
                              category="text-default-bold" class="mt-4">
                        {{ selectedSite.siteName }}
                    </app-text>

                    <!--Site Address - (Line 1 | Town)-->
                    <div v-if="selectedSite.siteAddress" class="d-flex align-start mt-4">

                        <v-icon class="icons8-home mr-4" color="primary"/>

                        <div class="d-flex flex-column">

                            <!--Address line 1-->
                            <app-text
                                v-if="selectedSite.siteAddress && selectedSite.siteAddress.addressLine1"
                                category="text-default">
                                {{ selectedSite.siteAddress.addressLine1 }},
                            </app-text>

                            <!--Town-->
                            <app-text
                                v-if="selectedSite.siteAddress && selectedSite.siteAddress.town && selectedSite.siteAddress.town"
                                category="text-default" class="mt-4">
                                {{ selectedSite.siteAddress.town }}
                            </app-text>

                        </div>

                    </div>

                    <!--Site Contact - (Contact name | Contact mobile number)-->
                    <app-text v-if="selectedSite.siteContact" category="text-default-bold" class="mt-4">
                        Site Manager
                    </app-text>
                    <div v-if="selectedSite.siteContact" class="d-flex align-start mt-4">

                        <v-icon class="icons8-person mr-4" color="primary"/>

                        <div class="d-flex flex-column">

                            <!--Contact Name-->
                            <app-text
                                v-if="selectedSite.siteContact && selectedSite.siteContact.contactName && selectedSite.siteContact.contactName"
                                category="text-default">
                                {{ selectedSite.siteContact.contactName }}
                            </app-text>

                            <!--Contact Number-->
                            <app-text
                                v-if="selectedSite.siteContact.contactTelephone"
                                category="text-default" class="mt-4">
                                {{ selectedSite.siteContact.contactTelephone }}
                            </app-text>
                        </div>

                    </div>

                    <!--Location Name (if applicable)-->
                    <div v-if="selectedLocation.locationName" class="d-flex align-start mt-4">

                        <v-icon class="icons8-marker mr-4" color="accent"/>

                        <div class="d-flex flex-column">

                            <app-text
                                v-if="selectedLocation && selectedLocation.locationName"
                                category="text-default">
                                {{ selectedLocation.locationName }}
                            </app-text>

                        </div>

                    </div>

                    <!--SWAPP in/out Button-->
                    <div v-if="$route.params.direction.toUpperCase() === 'OUT'">
                        <v-btn v-if="manualSwappReason" @click="manuallySwappInOut"
                               block class="mt-4" color="primary" :height="buttonSizeDefault">
                            SWAPP {{ $route.params.direction }} {{ $route.params.direction === 'in' ? 'to' : 'from' }}
                            this site
                        </v-btn>
                    </div>
                    <div v-else>
                        <v-btn v-if="selectedSite.siteName" @click="manuallySwappInOut"
                               block class="mt-4" color="primary" :height="buttonSizeDefault">
                            SWAPP {{ $route.params.direction }} {{ $route.params.direction === 'in' ? 'to' : 'from' }}
                            this site
                        </v-btn>
                    </div>

                </v-card>

            </v-col>

        </div>

        <!--Instructions-->
        <div v-if="$vuetify.breakpoint.width < 840" class="ma-4">
            <v-divider class="my-2"/>
            <app-text category="text-small-bold">Privacy</app-text>
            <app-text category="text-small">
                Your camera is only used for SWAPPing in and out, and it (or your microphone) is not used for any other
                purpose.
                We respect your privacy and do not access or use any of your personal data, other than what is held in
                the app.
            </app-text>
            <v-divider class="my-2"/>
        </div>

        <!--Display QR Code Result Message-->
        <div class="text-center ma-4" v-if="showSwappMessage">

            <app-text category="text-default-bold" class="justify-center mb-4">You have been</app-text>
            <app-text category="text-large" class="primary--text justify-center">SWAPP'd
                <span class="text-uppercase">{{ computedSwappInstructionInOut }}</span></app-text>

            <v-icon class="success--text" size="256">icons8-checkmark-yes</v-icon>

            <app-text category="text-default" class="justify-center text-uppercase">
                {{ computedSwappInstructionFromTo }}
            </app-text>

            <div v-if="locationDetails != null && locationDetails != undefined">
                <app-text category="text-large" class="primary--text justify-center text-uppercase mt-4">
                    {{ locationDetails.locationName }}
                </app-text>
            </div>

            <!--Home button-->
            <div class="mt-4">

                <!--If SWAPPing in, go to the Dashboard-->
                <v-btn v-if="$route.params.direction === 'in'" @click.native="MIX_go('/')"
                       color="primary" :height="buttonSizeDefault">
                    Home
                </v-btn>

                <!--If SWAPPing out, go to the Home page-->
                <v-btn v-else @click.native="MIX_go('/')" color="primary" :height="buttonSizeDefault">
                    Home
                </v-btn>
            </div>
        </div>

        <swapp-dialog :dialog.sync="showDialog" @closeDialog="closeDialog" :user="userData"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SwappDialog from "./swappDialog/swappDialog";

export default {

    name: "Swapp",

    components: {SwappDialog},

    data: () => ({
        form: {},
        hasLocationSelectionError: false,
        isManualSwapp: false,
        isSwappingInAndOut: false, // Required to SWAPP the user in and out together if required
        isTroubleSwappingVisible: false,
        locationDetails: [], // Store the qr code location result
        locationsData: [],
        locationsDataRenderable: [],
        selectedLocation: '',
        selectedSite: [],
        showDialog: false, // SWAPPing Issue dialog if the User is SWAPPing in without SWAPPing out first
        showSwappMessage: false, // Show qr code reader/result
        sitesData: [],
        userData: {},

        manualSwappReasons: ['Already left site', 'Camera not working', 'Cannot find QR code', 'Unable to scan QR code', 'Using a desktop'],
        manualSwappReason: '',
    }),

    computed: {
        ...mapGetters({
            GET_QR_Result: 'qr_code_reader_store/GET_getResult', // import qr code result from store
            GET_currentUser: 'GET_currentUser',
        }),

        /**
         * Return Swapp Value
         *
         * Check the route direction value and return the result.
         *
         * @returns {Number} SWAPP value
         */
        returnSwappValue() {
            const t = this

            return t.$route.params.direction === 'in' ? 1 : 0
        },

        /**
         * Swapp Type Message
         *
         * Returns a message for the manual SWAPP type button based on the selection of manual SWAPPing.
         *
         * @return {String} - the text for the button
         */
        swappTypeMessage() {
            const t = this
            let message = ''

            if (t.isTroubleSwappingVisible) {
                message = `${'SWAPP ' + t.$route.params.direction + ' with QR code'}`
            } else {
                message = `${'Can\'t SWAPP ' + t.$route.params.direction}?`
            }

            return message
        },

        /**
         * Computed Sites Data
         *
         * Sort the sites in to alphabetical order.
         *
         * @returns {Array} alphabetically sorted sites array
         */
        computedSitesData() {
            const t = this

            return t.sitesData.sort((a, b) => {
                return a.siteName > b.siteName ? 1 : -1
            })
        },

        /**
         * Computed Manual Swapp Title
         *
         * Return 'Having trouble...' message based on SWAPP direction and device.
         *
         * @returns {String} the message to render
         */
        computedManualSwappTitle() {
            const t = this
            let message = ''

            // If swapping from a mobile device
            if (t.$vuetify.breakpoint.width < 840) {

                // If swapping IN
                if (t.$route.params.direction.toUpperCase() === 'IN') {
                    message = 'Having Trouble SWAPPing in?'
                }

                // If swapping OUT
                else if (t.$route.params.direction.toUpperCase() === 'OUT') {
                    message = `Having Trouble SWAPPING out from ${t.userData.lastLocation.locationName}?`
                }
            }

            // If swapping from a desktop
            else {
                message = 'SWAPPing from a desktop?'
            }

            return message
        },

        /**
         * Computed Manual Swapp Instructions
         *
         * Return instructional text based on device type.
         *
         * @returns {String} the message to render
         */
        computedManualSwappInstructions() {
            const t = this

            return t.$vuetify.breakpoint.width > 840 && t.GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop')
                ? `If you\'re SWAPPING ${t.$route.params.direction} from a desktop, select an option from the list below.`
                : `If you're having trouble SWAPPING ${t.$route.params.direction} using the QR code, select the site from the list below.`
        },

        /**
         * Computed Swapp Instructions In/Out
         *
         * Return the direction for the result message, based on if the User is being SWAPPed In and OUT due to not
         * SWAPPing out from the previous location.
         *
         * @returns {String} direction to render
         */
        computedSwappInstructionInOut() {
            const t = this

            return t.isSwappingInAndOut ? 'IN' : t.$route.params.direction
        },

        /**
         * Computed Swapp Instruction From/To
         *
         * Return the instructional preposition  based on if the User is being SWAPPed In and OUT due to not SWAPPing
         * out from the previous location.
         *
         * @returns {String} preposition to render
         */
        computedSwappInstructionFromTo() {
            const t = this

            return t.isSwappingInAndOut ? 'TO' : t.$route.params.direction === 'in' ? 'to' : 'from'
        },

    },

    methods: {

        /**
         * Close Dialog
         *
         * If the user has triggered the 'SWAPPing Issue' dialog because they are trying to SWAPP IN to a location and
         * have not SWAPPed OUT of the last location, set these parameters to enable the re-SWAPP functionality,
         * and call to swapp().
         */
        closeDialog() {
            this.showDialog = false
            this.userData.swappStatus = 0
            this.isSwappingInAndOut = true
            this.swapp()
        },

        /**
         * Get Sites Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')

            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.sitesData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only push documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document)
                    }
                })
            })
        },

        /**
         * Get Locations Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites > Locations collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getLocationsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('locations')

            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.locationsData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only push documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.locationsData.push(document)
                    }
                })
            })
        },

        /**
         * Get User Collection Data
         *
         * Clear the table data to avoid duplications.
         * Fetch the current user's data from the users collection.
         *
         * @returns {Promise<void>}
         */
        async getUserCollectionData() {
            const t = this

            // Get collection data for the current user
            const collection = t.$firebase.db.collection('users').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            if (doc.exists) {
                t.userData = doc.data()
            } else {
                console.log('error fetch user document')
            }

        },

        /**
         * Handle Site Selection
         *
         * Triggered by a Site selection, sets the currently selected site.
         */
        handleSiteSelection() {
            const t = this
            let locationsArray = []

            t.selectedLocation = ''

            // If a site has more than one location
            if (t.selectedSite?.locations?.length > 1) {

                // Iterate over the selected site's locations
                t.selectedSite.locations.forEach(siteLocation => {

                    // Iterate over the locations data
                    t.locationsData.forEach(location => {

                        // If any of the locations match the siteLocation, add it to the array
                        if (siteLocation === location.id) {
                            locationsArray.push(location)
                        }
                    })
                })

                // Set the locations to be renderable in the select field
                t.locationsDataRenderable = locationsArray
            }

            // If a site only has a single location, render it
            else {
                t.locationsDataRenderable = []
                t.selectedLocation = t.selectedSite?.locations[0]
            }
        },

        /**
         * Manually Swapp In
         *
         * Set to manual swapping and call to getSiteLocation (site>location) with the manually selected location.
         * As the user should be SWAPPing out of the same location they're SWAPPed in to,
         * don't ask for the location, take it from the user data instead.
         *
         * @returns {Promise<void>}
         */
        async manuallySwappInOut() {
            const t = this
            let locationId = ''

            // If SWAPPing out, take the last location id from the user's data
            if (t.$route.params.direction.toUpperCase() === 'OUT') {
                locationId = t.userData.lastLocation.locationId
            } else if (t.selectedLocation.id) {
                locationId = t.selectedLocation.id
            } else {
                locationId = t.selectedLocation
            }

            // If no location has been selected, display an error
            if (!locationId) {
                t.hasLocationSelectionError = true
            } else {
                t.isManualSwapp = true
                await t.getSiteLocation(locationId)
            }
        },

        /**
         * Get Site Location
         *
         * Using either a manual ID or the result from the QR code, get the related location data.
         * If it reads ok, set locationDetails which will call the corresponding watch function.
         *
         * @param manualLocationId - the ID of the location from a manual selection
         * @returns {Promise<void>}
         */
        async getSiteLocation(manualLocationId) {
            const t = this

            // If SWAPPing manually, use the manual location id
            if (manualLocationId) {

                const locationResult = await t.MIX_readDocumentById('locations', manualLocationId);

                if (locationResult.code === 1) {
                    t.locationDetails = locationResult.data;
                } else {
                    console.log('locationResult : ' + locationResult.message)
                }
            }

            // If SWAPPing with the QR code get the QR results
            else if (t.GET_QR_Result !== null || t.GET_QR_Result !== '' || t.GET_QR_Result !== undefined) {

                const locationResult = await t.MIX_readDocumentById('locations', t.GET_QR_Result);

                if (locationResult.code === 1) {
                    t.locationDetails = locationResult.data;
                } else {
                    console.log('locationResult : ' + locationResult.message)
                }
            }
        },

        /**
         * SWAPP
         *
         * Populate the form based on the values selected.
         * If the User has not SWAPPed out of a site and is trying to SWAPP in to a different site,
         * open the 'SWAPPing Issue' dialog.
         * Save record to DB.
         *
         * @returns {Promise<void>}
         */
        async swapp() {
            const t = this
            const fields = {}

            // If the user is SWAPPed in, but is trying to SWAPP in to a location different to the current location
            // they're SWAPPed in to, open the 'SWAPPing Issue' dialog
            if (t.userData.swappStatus === 1) {
                // If re-SWAPPing in to a different location
                if (t.userData.lastLocation.locationId !== t.locationDetails.id) {
                    t.showDialog = true
                    return
                }
            }

            // Update the User's schedule (just today) to the new site
            await t.updateUserSchedule(t.locationDetails.site.siteName)

            fields.lastLocation = {}
            fields.lastSite = {}

            fields.lastLocation.locationId = t.locationDetails.id
            fields.lastLocation.locationName = t.locationDetails.locationName
            fields.lastSite.siteId = t.locationDetails.site.id
            fields.lastSite.siteName = t.locationDetails.site.siteName
            // Set the SWAPP status to IN if the 'SWAPPing Issue' modal has been activated, if not, set it to the direction it needs to be
            fields.swappStatus = t.isSwappingInAndOut ? 1 : t.returnSwappValue
            fields.swappMethod = t.isManualSwapp ? 'Manual' : 'QRphone'
            fields.lastLocation.manualSwappReason = t.manualSwappReason

            // Update the SWAPP status in the user document
            const updateDocumentResult = await t.MIX_updateDocumentFieldsById('users', t.GET_currentUser.id, fields)

            if (updateDocumentResult.code === 1) {
                t.MIX_alert(1, 'Swapp Status Updated', updateDocumentResult.data, null)
                t.showSwappMessage = true
            } else {
                t.MIX_alert(-1, 'ERROR - Could not update Status', null, updateDocumentResult.error)
                t.showSwappMessage = false
            }
        },

        /**
         * Update User Schedule
         *
         * To keep the User's schedule aligned with their daily activities (if they haven't updated it before moving sites),
         * update it with the details of the Site they have recently SWAPPed in to.
         */
        updateUserSchedule(siteName) {
            const t = this
            const dayToday = t.$moment().format('dddd').toLowerCase()

            let form = {
                [dayToday]: siteName
            }

            t.MIX_updateDocumentFieldsById('schedule', t.GET_currentUser.id, form)
        }

    },

    watch: {

        /**
         * Get QR Result
         *
         * Watch for the result of scanning a QR Code, and action accordingly.
         */
        GET_QR_Result: {
            handler() {
                const t = this

                t.getSiteLocation()
            }, deep: true
        },

        /**
         * Location Details
         *
         * Watch for a change to the location details, and action accordingly.
         */
        locationDetails: {
            handler() {
                const t = this

                t.swapp()
            },
            deep: true
        }
    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getSitesCollectionData()
        await t.getLocationsCollectionData()
        await t.getUserCollectionData()

        t.MIX_setQrCode(''); // prevents cached values for qr codes
    }
}
</script>
