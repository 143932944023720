var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4 ma-0",attrs:{"cols":_vm.GET_panelLayout.leftPanel}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"mb-4 pa-0",attrs:{"cols":"12"}},[_c('page-title',{attrs:{"icon":"icons8-ask-question","pageTitle":"Questions"}})],1),_c('v-col',{staticClass:"pa-0 mb-4",attrs:{"cols":_vm.$vuetify.breakpoint.width <= 600 ? 12 : 6}},[_c('v-text-field',{attrs:{"background-color":"white","clearable":"","filled":"","hide-details":"","label":_vm.$t('filters.search'),"prepend-inner-icon":"icons8-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-end pa-0 mb-4"},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.width <= 600,"color":"primary","height":_vm.buttonSizeDefault},nativeOn:{"click":function($event){return _vm.openItem('', 'New', false)}}},[_c('v-icon',{staticClass:"icons8-ask-question mr-4"}),_c('app-text',{attrs:{"category":"text-default"}},[_vm._v("New Question")])],1)],1)],1),_c('v-row',{staticClass:"ma-0"},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-col',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-0 que-table",attrs:{"calculate-widths":"","headers":_vm.computedHeaders,"items":_vm.tableData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.id))])]}},{key:"item.questionName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.questionName))])]}},{key:"item.questionDescription",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.questionDescription))])]}},{key:"item.questionAnswers",fn:function(ref){
var item = ref.item;
return _vm._l((item.questionAnswers),function(answer){return _c('div',{staticClass:"bottomBorder"},[_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(answer))])],1)})}},{key:"item.questionCorrectAnswers",fn:function(ref){
var item = ref.item;
return _vm._l((item.questionCorrectAnswers),function(answer){return _c('div',{staticClass:"bottomBorder"},[_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(answer))])],1)})}},{key:"item.questionPosition",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.questionPosition))])]}},{key:"item.questionSiteIDs",fn:function(ref){
var item = ref.item;
return _vm._l((item.questionSiteIDs),function(site){return _c('div',{staticClass:"bottomBorder"},[_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(site.siteName))])],1)})}},{key:"item.questionUserCategories",fn:function(ref){
var item = ref.item;
return _vm._l((item.questionUserCategories),function(category){return _c('div',{staticClass:"bottomBorder"},[_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(category))])],1)})}},{key:"item.questionType",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.questionType))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"white text--red",attrs:{"depressed":""},on:{"click":function($event){return _vm.openItem(item.id, 'View', false)}}},[_vm._v("Open "),_c('v-icon',{staticClass:"icons8-forward",attrs:{"color":"primary"}})],1)],1)]}}],null,false,499252756)})],1):_vm._e()],1),_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-col',_vm._l((_vm.filteredTableData),function(question){return _c('div',{key:question.id},[_c('v-card',{staticClass:"mb-4 pa-4",on:{"click":function($event){return _vm.openItem(question.id, 'View', false)}}},[_c('app-text',{attrs:{"category":"text-default-bold"}},[_vm._v(_vm._s(question.questionName))]),_c('app-text',{attrs:{"category":"text-default"}},[_vm._v(_vm._s(question.questionType))])],1)],1)}),0):_vm._e()],1)],1),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn animate__faster","leave-active-class":"animate__animated animate__fadeOut animate__faster","mode":"out-in"}},[_c('rightpanel')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }