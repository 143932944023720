<!--
Privacy Policy

Renders the Privacy Policy as supplied by Andrew Scott.
-->
<template>
    <div class="pa-4" style="width: 100%">

        <page-title icon="icons8-privacy-policy" page-title="Privacy Policy"/>
        <app-text category="text-default">
            Revision:
            <span class="font-weight-bold">Rev 0</span>
            - Date:
            <span class="font-weight-bold">14th January 2020</span>
        </app-text>

        <v-divider class="mt-4"/>

        <!--Introduction-->
        <app-text category="text-medium" class="primary--text mt-4 mb-2">Introduction</app-text>
        <app-text category="text-default">
            Andrew Scott needs to gather and use certain information about individuals.
            <br><br>
            This can include customers, suppliers, business contacts, employees and other people the organisation has a relationship with or may need to contact.
            <br><br>
            This policy describes how this data must be collected, handled and stored to meet the company’s data protection standards – and to comply with the law.
        </app-text>

        <!--Why this policy exists-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Why this Policy Exists</app-text>
        <app-text category="text-default">
            This data protection policy ensures Andrew Scott:
            <br><br>
            <div class="ml-4">
                ● Complies with data protection law and follow good practice<br>
                ● Protects the rights of staff, customers and partners<br>
                ● Is open about how it stores and processes individuals’ data<br>
                ● Protects itself from the risks of a data breach
            </div>
        </app-text>

        <!--Data Protection Law-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Data Protection Law</app-text>
        <app-text category="text-default">
            The General Data Protection Regulations 2017 describes how organisations –Including Andrew Scott – must collect, handle and store personal information.
            <br><br>
            These rules apply regardless of whether data is stored electronically, on paper or on other materials.
            <br><br>
            To comply with the law, personal information must be collected and used fairly, stored safely and not disclosed unlawfully.
            <br><br>
            The Data Protection Act 1998 is underpinned by eight important principles. These say that personal data must:
            <br><br>
            <div class="ml-4">
                1. Be processed fairly and lawfully <br>
                2. Be obtained only for specific, lawful purposes <br>
                3. Be adequate, relevant and not excessive <br>
                4. Be accurate and kept up to date <br>
                5. Not to be held for any longer than necessary <br>
                6. Processed in accordance with the rights of data subjects <br>
                7. Be protected in appropriate ways <br>
                8. Not be transferred outside of the European Economic Area (EEA), unless that country or territory also ensures an adequate level of protection
            </div>
        </app-text>

        <!--People, Risks and Responsibilities-->
        <app-text category="text-medium-bold" class="primary--text mt-8 mb-2">People, Risks and Responsibilities</app-text>

        <!--People Scope-->
        <app-text category="text-medium" class="primary--text mb-2">People Scope</app-text>
        <app-text category="text-default">
            This policy applies to:
            <br><br>
            <div class="ml-4">
                ● The head office of Andrew Scott <br>
                ● All branches of Andrew Scott <br>
                ● All staff and volunteers of Andrew Scott <br>
                ● All contractors, suppliers and other people working on behalf of Andrew Scott
            </div>
            <br>
            It applies to all data that Andrew Scott holds relating to identifiable individuals, even if that information technically falls outside of the Data Protection Act 1998. This can include:
            <br><br>
            <div class="ml-4">
                ● Names of individuals <br>
                ● Postal addresses <br>
                ● Email addresses <br>
                ● Telephone numbers <br>
                ● Plus, any other information relating to individuals
            </div>
        </app-text>

        <!--Data Protection Risks-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Data Protection Risks</app-text>
        <app-text category="text-default">
            This policy helps to protect Andrew Scott from some very real data security risks, including:
            <br><br>
            <div class="ml-4">
                ● Breaches of confidentiality. For instance, information being given out inappropriately. <br>
                ● Failing to offer choice. For instance, all individuals should be free to choose how the company uses data relating to them. <br>
                ● Reputational damage. For instance, Andrew Scott could suffer if hackers successfully gained access to sensitive data.
            </div>
        </app-text>

        <!--Responsibilities-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Responsibilities</app-text>
        <app-text category="text-default">
            Everyone who works for or with Andrew Scott has the responsibility for ensuring data is collected, stored and held appropriately.
            <br><br>
            Each team that handles personal data must ensure that it is handled and processed in line with this policy and data protection principles.
            <br><br>
            However, these people have key areas of responsibility:
            <br><br>
            <div class="ml-4">
                ● The board of directors is ultimately responsible for ensuring that Andrew Scott meets its legal obligations.
                <br><br>
                ● The data protection officer, David Hadley, is responsible for:
                <br><br>
                <div class="ml-4">
                    o Keeping the board updated about data protection responsibilities, risks and issues. <br>
                    o Reviewing all data protection procedures and related policies, in line with an agreed schedule. <br>
                    o Arranging data protection training and advice for the people covered by this policy. <br>
                    o Handling data protection questions from staff and anyone else covered by this policy. <br>
                    o Dealing with requests from individuals to see the data Andrew Scott holds about them (also called ‘subject access requests’) <br>
                    o Checking and approving any contacts or agreements with third parties that may handle the company’s sensitive data.
                </div>
                <br>
                ● The IT sub-contractor, Vindico, is responsible for:
                <br><br>
                <div class="ml-4">
                    o Ensuring all systems, services and equipment used for storing data meet acceptable security standards.<br>
                    o Performing regular checks and scans to ensure security hardware and software is functioning properly. <br>
                    o Evaluating any third-party services Andrew Scott is considering using to store or process data. For instance, cloud computing services.
                </div>
                <br>
                ● The HR manager Hayley Lodwig, is responsible for:
                <br><br>
                <div class="ml-4">
                    o Approving any data protection statements attached to communications such as emails and letters. <br>
                    o Addressing any data protection queries from journalists or media outlets like newspapers. <br>
                    o Where necessary, working with other staff to ensure marketing initiatives abide by data protection law and principles.
                </div>
            </div>
        </app-text>

        <!--General Staff Guidelines-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">General Staff Guidelines</app-text>
        <app-text category="text-default">
            <div class="ml-4">
                ● The only people able to access data covered by this policy should be those who need the data to accomplish their work.
                <br><br>
                ● Data should not be shared informally. When access to confidential information is required, employees can request it from their line managers.
                <br><br>
                ● Andrew Scott will provide training to all employees to help them understand their responsibilities when handling data.
                <br><br>
                ● Employees should keep all data secure, by taking sensible precautions and following the guidelines below.
                <br><br>
                ● Strong passwords must be used and they should never be shared.
                <br><br>
                ● Personal data should not be disclosed to unauthorised people, either within the company or externally.
                <br><br>
                ● Data should be regularly reviewed and updated if it is found to be out of date. If no longer required, it should be deleted and disposed of.
                <br><br>
                ● Employees should request help from their line manager or the data protection officer if they are unsure about any aspect of data protection.
                <br><br>
                ● Employees should ensure that laptops/desktops are locked when staff are away from their desks,(screens to be automatically locked).
                <br><br>
                ● Removable media should not be used at any time, unless specific permission is obtained.
                <br><br>
                ● Duel factor authentication to be used when accessing company files or documents.
            </div>
        </app-text>

        <!--Data Storage-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Data Storage</app-text>
        <app-text category="text-default">
            These rules describe how and where data should be safely stored. Questions about storing data safely can be directed at the IT manager or data controller.
            <br><br>
            When data is stored on paper, it should be kept in a secure place where unauthorised people cannot see it.
            <br><br>
            These guidelines also apply to data that is usually stored electronically but has been printed out for some reason.
            <br><br>
            <div class="ml-4">
                ● When not required, the paper or files should be kept in a locked drawer or filing cabinet.
                <br><br>
                ● Employees should make sure paper and printouts are not left where unauthorised people could see them, like on a printer.
                <br><br>
                ● Data printouts should be shredded and disposed of securely when no longer required.
            </div>
            <br>
            When data is stored electronically, it must be protected from unauthorised access, accidental deletion and malicious hacking attempts:
            <br><br>
            <div class="ml-4">
                ● Data should be protected by strong passwords that are changed regularly and never shared between employees.
                <br><br>
                ● Data should only be stored on designated drives and servers, and should only be uploaded to an approved cloud computing service.
                <br><br>
                ● Servers containing personal data should be sited in a secure location, away from general office space.
                <br><br>
                ● Data should be backed up frequently. Those backups should be tested regularly, in line with Andrew Scott standard backup procedures.
                <br><br>
                ● Data should never be saved directly to laptops or other mobile devices like tablets or smartphones.
                <br><br>
                ● All servers and computers containing data should be protected by approved security software and a firewall.
            </div>
        </app-text>

        <!--Data Use-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Data Use</app-text>
        <app-text category="text-default">
            Personal data is of no value to Andrew Scott unless the business can make use of it. However, it is when personal data is accessed and used that it can be at the greatest risk of loss, corruption or theft:
            <br><br>
            <div class="ml-4">
                ● When working with personal data, employees should ensure the screens of their computers are always locked when left unattended.
                <br><br>
                ● Personal data should not be shared informally. It should never be sent by email, as this form of communication is not secure.
                <br><br>
                ● Data must be encrypted before being transferred electronically. The IT manager can explain how to send data to authorised external contacts.
                <br><br>
                ● Personal data should never be transferred outside of the European Economic Area.
                <br><br>
                ● Employees should not save copies of personal data to their own computers. Always access and update the central copy of any data.
            </div>
            <br>
            The law requires Andrew Scott to take reasonable steps to ensure data is kept accurate and up to date.
            <br><br>
            The more important it is that the personal data Is accurate, the greater the effort Andrew Scott should put into ensuring its accuracy.
            <br><br>
            It is the responsibility of all employees who work with data to take reasonable steps to ensure it is kept as accurate and up to date as possible.
            <br><br>
            <div class="ml-4">
                ● Data will be held in as few places as necessary. Staff should not create any unnecessary additional data sets.
                <br><br>
                ● Staff should take every opportunity to ensure data is updated. For instance, by confirming a customer’s details when they call.
                <br><br>
                ● Andrew Scott will make it easy for data subjects to update the information Andrew Scott holds about them. For instance, via the company website.
                <br><br>
                ● Data should be updated as inaccuracies are discovered. For instance, if a customer can no longer be reached on their stored telephone number, it should be removed from the database.
                <br><br>
                ● It is the marketing manager’s responsibility to ensure marketing databases are checked against industry suppression files every six months.
            </div>
        </app-text>

        <!--Subject Access Requests-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Subject Access Requests</app-text>
        <app-text category="text-default">
            All individuals who are subject of personal data held by Andrew Scott are entitled to:
            <br><br>
            <div class="ml-4">
                ● Ask what information Andrew Scott holds about them and why.
                <br><br>
                ● Ask how to gain access to it.
                <br><br>
                ● Be informed how to keep it up to date.
                <br><br>
                ● Be informed how Andrew Scott is meeting data protection obligations.
            </div>
            <br>
            If an individual contacts the company requesting this information, this is called a subject access request.
            <br><br>
            Subject access requests from individuals should be made by email, addressed to the data controller at david.hadley@andrewscott.co.uk. The data controller can supply a standard request form, although individuals do not have to use this.
            <br><br>
            Individuals will be charged £10 per subject access request. The data controller will aim to provide relevant data within 14 days.
            <br><br>
            The data controller will always verify the identity of anyone making a subject access request before handing out any information.
        </app-text>

        <!--Disclosing Data For Other Reasons-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Disclosing Data For Other Reasons</app-text>
        <app-text category="text-default">
            In certain circumstances, the Data Protection Act allows personal data to be disclosed to law enforcement agencies without the consent of the data subject.
            <br><br>
            Under these circumstances, Andrew Scott will disclose requested data. However, the data controller will ensure that request is legitimate, seeking assistance from the board and from Andrew Scott’s legal advisers where necessary.
        </app-text>

        <!--Providing Information-->
        <app-text category="text-medium" class="primary--text mt-8 mb-2">Providing Information</app-text>
        <app-text category="text-default">
            Andrew Scott aims to ensure that individuals are aware that their data is being processed, and that they understand:
            <br><br>
            <div class="ml-4">
                ● How the data is being used
                <br><br>
                ● How to exercise their rights
            </div>
            <br>
            To these ends, the company has a privacy statement, setting out how data relating to individuals is used by Andrew Scott.
            <br><br>
            [This policy is available upon request. A version of this statement is also available on our IMS system]
        </app-text>

        <v-divider class="my-4"/>

        <!--Signed by...-->
        <app-text category="text-default">
            I hereby agree to adhere to these policies at all times.
        </app-text>

        <div class="ml-4">
            <app-text category="text-default" class="mt-4">
                <span class="font-weight-bold">M. Bowen</span>
                <br><br>
                <span class="font-italic">Managing Director (with Health, Safety and Environmental Responsibilities)</span>
                <br><br>
                <span class="primary--text">Andrew Scott Limited</span>
            </app-text>
        </div>

        <v-divider class="my-4"/>

        <app-text category="text-default">
            Revision:
            <span class="font-weight-bold">Rev 0</span>
            - Date:
            <span class="font-weight-bold">14th January 2020</span>
        </app-text>

    </div>
</template>

<script>
export default {

    name: "privacyPolicy",

}
</script>
