<!--
Staff Directory

Renders the Staff Directory page which displays the details for staff members.
This renders as either a table (tablets and desktops) or cards (mobile).

Staff Directory also has a form for adding/editing/deleting staff details, and viewing their location history.
The form can also be used to SWAPP staff in/out.
-->
<template>
    <v-row no-gutters class="fill-height noprint" style="width: 100% !important;">

        <!--
        Left side
         - Main part of the page
        -->
        <v-col :cols="GET_panelLayout.leftPanel">
            <v-row class="pa-4 ma-0" dense>

                <!--
                Header | Filter Buttons | Action Buttons
                -->
                <v-col cols="12" xs="12" sm="12">

                    <!--Header-->
                    <v-row class="mb-0" dense>

                        <!--
                        Header - left
                        -->
                        <v-col class="pa-0">

                            <!--Page title-->
                            <page-title icon="icons8-people" pageTitle="Staff"/>

                            <!--
                            Search Box | Areas
                            -->
                            <v-row class="ma-0 mb-4">

                                <!--Search-->
                                <v-col class="pa-0" cols="12">
                                    <v-text-field background-color="white" v-model="search"
                                                  prepend-inner-icon="icons8-search" filled dense
                                                  :label="$t(`filters.search`)" clearable
                                                  hide-details="auto"/>
                                </v-col>

                            </v-row>

                        </v-col>

                        <!--
                        Header - right
                        -->
                        <v-col v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 664"
                               class="d-flex align-start justify-end pa-0">
                            <!-- // * STATS (CUSTOM) -->
                            <div>
                                <v-col class="d-flex align-right pr-0" cols="12" xs="12" sm="6">

                                    <!--Total users-->
                                    <div>
                                        <v-card flat width="112" height="104"
                                                class="d-flex flex-column justify-space-between pa-2">

                                            <app-text category="text-xxlarge-bold" class="grey--text justify-center">
                                                <number ref="numberExample" :to="tableData.length"
                                                        :duration="0.5"/>
                                            </app-text>

                                            <app-text category="text-small"
                                                      class="grey--text justify-center text-center">
                                                {{ $t(`staffDirectory.stats.totalUsers`) }}
                                            </app-text>

                                        </v-card>
                                    </div>

                                </v-col>
                            </div>
                        </v-col>
                    </v-row>

                    <!--
                    Filter buttons | Action buttons
                    -->
                    <v-row v-if="['SA'].includes(GET_currentUser.userLevel)" class="mt-0" dense>

                        <!--Toggle filters | Action buttons-->
                        <v-col class="d-flex align-end pa-0 mb-4" cols="12">

                            <!--Status-->
                            <div>

                                <!--Title-->
                                <app-text category="text-small-bold" class="grey--text">STATUS</app-text>

                                <!--Toggle buttons-->
                                <v-btn-toggle v-model="filter.status" multiple color="white" class="mr-4"
                                              active-class="white" background-color="lightgrey" dense>

                                    <!--In-->
                                    <v-btn :value="1" large :height="buttonSizeDefault">
                                        <v-icon class="icons8-qr-code" color="success"/>
                                        <app-text category="text-small" class="grey--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`status.in`) }}
                                        </app-text>
                                    </v-btn>

                                    <!--Out-->
                                    <v-btn :value="0" large :height="buttonSizeDefault">
                                        <v-icon class="icons8-qr-code" color="grey"/>
                                        <app-text category="text-small" class="grey--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`status.out`) }}
                                        </app-text>
                                    </v-btn>

                                </v-btn-toggle>

                            </div>

                            <!--Access-->
                            <div v-if="$vuetify.breakpoint.mdAndUp">

                                <!--Title-->
                                <app-text category="text-small-bold" class="grey--text">ACCESS</app-text>

                                <!--Toggle buttons-->
                                <v-btn-toggle v-model="filter.userStatus" multiple color="white" class="mr-4"
                                              active-class="white" background-color="lightgrey" dense>

                                    <!--Approved-->
                                    <v-btn value="APPROVED" large :height="buttonSizeDefault">
                                        <v-icon color="success" class="icons8-checkmark-yes"/>
                                        <app-text category="text-small" class="success--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`filters.approved`) }}
                                        </app-text>
                                    </v-btn>

                                    <!--Pending-->
                                    <v-btn value="PENDING" large :height="buttonSizeDefault">
                                        <v-icon color="accent" class="icons8-circle"/>
                                        <app-text category="text-small" class="accent--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`filters.pending`) }}
                                        </app-text>
                                    </v-btn>

                                    <!--Rejected-->
                                    <v-btn value="REJECTED" large :height="buttonSizeDefault"
                                           v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                        <v-icon color="warning" class="icons8-cancel"/>
                                        <app-text category="text-small" class="warning--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`filters.rejected`) }}
                                        </app-text>
                                    </v-btn>

                                    <!--Suspended-->
                                    <v-btn value="SUSPENDED" large :height="buttonSizeDefault"
                                           v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                        <v-icon color="warning" class="icons8-private"/>
                                        <app-text category="text-small" class="warning--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`filters.suspended`) }}
                                        </app-text>
                                    </v-btn>

                                    <!--Archived-->
                                    <v-btn value="ARCHIVED" large :height="buttonSizeDefault"
                                           v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                        <v-icon color="grey" class="icons8-xbox-a"/>
                                        <app-text category="text-small" class="grey--text"
                                                  v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.xlOnly">
                                            {{ $t(`filters.archived`) }}
                                        </app-text>
                                    </v-btn>

                                </v-btn-toggle>

                            </div>

                            <v-spacer/>

                            <!--Action buttons-->
                            <div>

                                <!--Title-->
                                <app-text v-if="$vuetify.breakpoint.width >= 600" category="text-small-bold"
                                          class="grey--text">ACTIONS
                                </app-text>

                                <!--Buttons-->
                                <div class="d-flex flex-row">

                                    <!--Add - not currently being used as creating a user doesn't create any authentication-->
                                    <!--<v-btn v-if="$vuetify.breakpoint.width > 664"-->
                                    <!--	   @click.native="openItem('', 'New', false)"-->
                                    <!--	   class="mr-2" color="primary" depressed :height="buttonSizeDefault">-->
                                    <!--	<v-icon class="icons8-people mr-2"/>-->
                                    <!--	<app-text v-if="$vuetify.breakpoint.width >= 800" category="text-default">-->
                                    <!--		New User-->
                                    <!--	</app-text>-->
                                    <!--</v-btn>-->

                                    <!--Columns-->
                                    <v-btn v-if="$vuetify.breakpoint.width >= 600"
                                           @click.native="columnsDialog = true"
                                           class="mr-2" color="primary" depressed :height="buttonSizeDefault">
                                        <v-icon class="icons8-select-column mr-2"/>
                                        <app-text v-if="$vuetify.breakpoint.width >= 964" category="text-default">
                                            Columns
                                        </app-text>
                                    </v-btn>

                                    <!--Export-->
                                    <v-btn v-if="$vuetify.breakpoint.mdAndUp"
                                           @click.native="MIX_exportDocuments(headersCSV,'Staff Directory',formatExport)"
                                           class="mr-2" color="primary" depressed :height="buttonSizeDefault">
                                        <v-icon class="icons8-export-csv mr-2"/>
                                        <app-text v-if="$vuetify.breakpoint.width >= 964" category="text-default">
                                            Export
                                        </app-text>
                                    </v-btn>

                                </div>

                            </div>

                        </v-col>

                        <!--Add button - mobile only - not currently being used as creating a user doesn't create any authentication-->
                        <!--<v-col class="pa-0 mb-4">-->
                        <!--	<v-btn v-if="$vuetify.breakpoint.width <= 664" @click.native="openItem('', 'New', false)"-->
                        <!--		   block color="primary" :height="buttonSizeDefault">-->
                        <!--		<v-icon class="icons8-people mr-4"/>-->
                        <!--		<app-text category="text-default">Add New User</app-text>-->
                        <!--	</v-btn>-->
                        <!--</v-col>-->

                    </v-row>

                </v-col>

                <!--
                Table
                 - This will be rendered on larger-screened devices
                -->
                <v-col v-if="$vuetify.breakpoint.mdAndUp"
                       class="pa-0 tabledata" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <v-data-table class="elevation-0"
                                  :headers="computedHeaders"
                                  hide-default-header
                                  :items="computedTableData"
                                  :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
                                  item-key="id"
                                  sort-by="userName"
                                  :search="search">

                        <!-- Headers -->
                        <template v-slot:header="{ props }">
                            <th v-for="(head, index) in props.headers" :key="index" :align="head.align" class="pa-4">
                                <app-text category="text-small" class="grey--text">{{ head.text }}</app-text>
                            </th>
                        </template>

                        <!-- Registered -->
                        <template v-slot:[`item.registered`]="{ item }">
                            <div class="text-left">
                                <v-icon v-if="item.authId !== null" color="success" class="icons8-verified-account"/>
                                <v-icon v-else color="grey" class="icons8-customer"/>
                            </div>
                        </template>

                        <!-- User status (Access) -->
                        <template v-slot:[`item.userStatus`]="{ item }">
                            <div class="text-left">
                                <v-icon v-if="item.userStatus === 'PENDING'" class="icons8-circle" color="accent"/>
                                <v-icon v-if="item.userStatus === 'REJECTED'" class="icons8-cancel" color="warning"/>
                                <v-icon v-if="item.userStatus === 'APPROVED'" class="icons8-checkmark-yes"
                                        color="success"/>
                                <v-icon v-if="item.userStatus === 'SUSPENDED'" class="icons8-private" color="error"/>
                                <v-icon v-if="item.userStatus === 'ARCHIVED'" class="icons8-xbox-a" color="grey"/>
                            </div>
                        </template>

                        <!-- Swapp status -->
                        <template v-slot:[`item.swappStatus`]="{ item }">

                            <!--Out-->
                            <div v-if="item.swappStatus === 0" class="d-flex">
                                <v-icon color="grey" class="icons8-filled-circle mr-2"/>
                                <app-text category="text-small-bold" class="grey--text">OUT</app-text>
                            </div>

                            <!--In-->
                            <div v-if="item.swappStatus === 1" class="d-flex">
                                <v-icon color="success" class="icons8-filled-circle mr-2"/>
                                <app-text category="text-small-bold" class="grey--text">IN</app-text>
                            </div>

                            <!--Sick-->
                            <div v-if="item.swappStatus === 2" class="d-flex">
                                <v-icon color="warning" class="icons8-filled-circle mr-2"/>
                                <app-text category="text-small-bold" class="grey--text">SICK</app-text>
                            </div>

                            <!--Leave-->
                            <div v-if="item.swappStatus === 3" class="d-flex">
                                <v-icon color="warning" class="icons8-filled-circle mr-2"/>
                                <app-text category="text-small-bold" class="grey--text">LEAVE</app-text>
                            </div>

                        </template>

                        <!-- Action button -->
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn @click="openItem(item.id, 'View', false)" depressed class="primary--text white pa-4">
                                Open
                                <v-icon class="icons8-forward" color="primary"/>
                            </v-btn>
                        </template>

                    </v-data-table>

                </v-col>

                <!--
                Mobile Cards
                 - This will be rendered in place of the table on smaller-screened devices
                -->
                <v-col v-if="$vuetify.breakpoint.smAndDown" class="pa-0">
                    <div v-for="item in filteredComputedTableData" :key="item.id">

                        <staff-user-modal :user="item"/>

                    </div>
                </v-col>

            </v-row>
        </v-col>

        <!--
        Right panel
         - Form
         -->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animated__faster"
                    leave-active-class="animate__animated animate__fadeOut animated__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
            <!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
        </transition>

        <!--
        Column Dialog Box
        -->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card>

                <!--Title-->
                <app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers" :key="index">
                            <app-text category="text-default">
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false" :label="header.text"/>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import staffUserModal from "./staffUserModal/staffUserModal"

export default {
    name: "StaffDirectory",

    components: {
        staffUserModal
    },

    data: () => ({
        title: "Staff Directory",
        allData: [],
        collection: "staffDirectory",
        collectionItem: "staffDirectory",
        collectionItemTitle: "Staff Directory",
        columnsDialog: false,
        customWhere: [],
        exportStaff: [],
        filter: {
            status: [1, 0, 2, 3],
            areas: [],
            userStatus: ['APPROVED', 'PENDING'],
            training: ['valid', 'required', 'none']
        },
        headers: [
            {
                text: "ID",
                value: "id",
                align: "start",
                sortable: false,
                hidden: true,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Name",
                value: "userName",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Telephone Number",
                value: "userTelephone",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Access",
                value: "userStatus",
                align: "center",
                sortable: false,
                hidden: false,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Registered",
                value: "registered",
                align: "center",
                sortable: false,
                hidden: true,
                hide: true,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Status",
                value: "swappStatus",
                align: "start",
                sortable: false,
                hidden: false,
                hide: false,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Last Location",
                value: "lastLocation.locationName",
                align: "start",
                sortable: false,
                hidden: false,
                hide: true,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "Last Site",
                value: "lastSite.siteName",
                align: "start",
                sortable: false,
                hidden: false,
                hide: true,
                hasAccess: ['SA', 'SU']
            },
            {
                text: "",
                value: "actions",
                align: "right",
                sortable: false,
                hidden: false,
                hide: false,
                width: "100px",
                hasAccess: ['SA']
            },
        ],
        headersCSV: {
            userName: 'User Name',
            userTelephone: 'User Telephone',
            userEmail: 'User Email',
            userAddress: 'User Address',
            userPostcode: 'User Postcode',
            userTitle: 'User Job Title',
            userType: 'User Type',
            lastLocation: 'Last Location',
            lastSite: 'Last Site',
            userLevel: 'User Level',
            userRole: 'User Role',
            userStatus: 'Access',
            trainingStatus: 'Training Status',
        },
        search: "", // Table search,
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: "GET_panelLayout",
            GET_currentUser: "GET_currentUser",
            GET_areas: "GET_areas",
            GET_lookupValues: 'GET_lookupValues'
        }),

        //Format Of the Export File
        formatExport() {
            this.exportStaff = [];
            var staff = JSON.parse(JSON.stringify(this.computedTableData));
            for (var i = 0; i < staff.length; i++) {
                var userName = "";
                if (staff[i].userName) {
                    userName = staff[i].userName;
                } else {
                    userName = "UNKNOWN"
                }
                var userTelephone = "";
                if (staff[i].userTelephone) {
                    userTelephone = staff[i].userTelephone;
                } else {
                    userTelephone = "UNKNOWN"
                }
                var userEmail = "";
                if (staff[i].userEmail) {
                    userEmail = staff[i].userEmail;
                } else {
                    userEmail = "UNKNOWN"
                }
                var userAddress = "";
                if (staff[i].userAddress.userAddressLine1) {
                    userAddress = staff[i].userAddress.userAddressLine1;
                } else {
                    userAddress = "UNKNOWN"
                }
                var userPostcode = "";
                if (staff[i].userAddress.userPostcode) {
                    userPostcode = staff[i].userAddress.userPostcode;
                } else {
                    userPostcode = "UNKNOWN"
                }
                var userTitle = "";
                if (staff[i].userJobTitle) {
                    userTitle = staff[i].userJobTitle;
                } else {
                    userTitle = "UNKNOWN"
                }
                var userType = "";
                if (staff[i].userType) {
                    userType = staff[i].userType;
                } else {
                    userType = "UNKNOWN"
                }
                var lastLocation = "";
                if (staff[i].lastLocation.locationName) {
                    lastLocation = staff[i].lastLocation.locationName;
                } else {
                    lastLocation = "UNKNOWN"
                }
                var lastSite = "";
                if (staff[i].lastSite.siteName) {
                    lastSite = staff[i].lastSite.siteName;
                } else {
                    lastSite = "UNKNOWN"
                }
                var userLevel = "";
                if (staff[i].userLevel) {
                    userLevel = staff[i].userLevel;
                } else {
                    userLevel = "UNKNOWN"
                }
                var userRole = "";
                if (staff[i].userRole) {
                    userRole = staff[i].userRole;
                } else {
                    userRole = "UNKNOWN"
                }
                var userStatus = "";
                if (staff[i].userStatus) {
                    userStatus = staff[i].userStatus;
                } else {
                    userStatus = "UNKNOWN"
                }
                var trainingStatus = "";
                if (staff[i].trainingStatus) {
                    trainingStatus = staff[i].trainingStatus;
                } else {
                    trainingStatus = "UNKNOWN"
                }
                this.exportStaff.push({
                    userName: userName,
                    userTelephone: userTelephone,
                    userEmail: userEmail,
                    userAddress: userAddress,
                    userPostcode: userPostcode,
                    userTitle: userTitle,
                    userType: userType,
                    lastLocation: lastLocation,
                    lastSite: lastSite,
                    userLevel: userLevel,
                    userRole: userRole,
                    userStatus: userStatus,
                    trainingStatus: trainingStatus,
                })
            }
            return this.exportStaff;
        },

        /**
         * Computed Headers
         *
         * Only display headers that are not set to hidden and the user has access to (via userLevel).
         *
         * @returns {[headers]} - filtered headers to render in the table
         */
        computedHeaders() {
            const t = this
            const headers = t.headers
            let filteredHeaders = []

            headers.forEach(header => {
                if (header.hasAccess &&
                    header.hasAccess.includes(t.GET_currentUser.userLevel) &&
                    !header.hidden) {

                    filteredHeaders.push(header)
                }
            })

            return filteredHeaders
        },

        computedTableData() {
            const t = this
            let tableData = t.tableData

            // Filter by User Status for Access
            tableData = tableData.filter(function (item) {
                return t.filter.userStatus.includes(item.userStatus)
            })

            // Filter by SWAPP status
            tableData = tableData.filter(function (item) {
                return t.filter.status.includes(item.swappStatus)
            })

            return tableData
        },

        /**
         * Filtered Computed Table Data
         *
         * Take the computedTableData and return an array of users filtered by the search string.
         *
         * @returns array - users filtered by search string, ordered alphabetically
         */
        filteredComputedTableData() {
            const t = this

            return t.computedTableData.filter(user => user.userName.toLowerCase().match(t.search.toLowerCase()))
        }
    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openStaffDirectory",
            ACT_openStaffDirectory: "ACT_openStaffDirectory",
        }),

        async getTableData() {
            const t = this

            let collection = t.$firebase.db.collection("users")

            if (JSON.stringify(t.customWhere) !== "[]") {

                for (let i = 0; i < t.customWhere.length; i++) {

                    const query = {
                        field: t.customWhere[i].key,
                        operator: t.customWhere[i].operator,
                        value: t.customWhere[i].value
                    }

                    collection = collection.where(query.field, query.operator, query.value)
                }
            }

            collection.onSnapshot(function (querySnapshot) {

                // Rest array to avoid data duplication
                t.tableData = []

                querySnapshot.forEach(function (doc) {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty("delete")) {
                        t.tableData.push(document)
                        t.allData.push(document)
                    } else if (t.showDeleted) {
                        t.tableData.push(document)
                    } else {
                        // Do not Show Concern
                    }
                })

                t.tableData = t.tableData.sort((a, b) => {
                    return a.userName > b.userName ? 1 : -1
                })
            })
        },

        async openItem(id, process, popup) {
            const t = this

            // Only allow opening if the user has access
            if (['SA'].includes(t.GET_currentUser.userLevel)) {
                const item = {}

                item.process = process
                item.popup = popup

                // If this is NOT a new document, fetch the user data by id
                if (item.process !== "New") {
                    const itemResult = await t.MIX_readDocumentById("users", id)
                    item.data = itemResult.data
                } else {
                    item.data = null
                }

                t.ACT_openItem(item)

                if (t.$vuetify.breakpoint.lgAndDown) {
                    t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                    t.MIX_setPanelLayout(12, 0, false, "")
                } else {
                    t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
                    // * HIDE HEADERS
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = true
                        }
                    }
                }
            }
        },

    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, "") // Hide Right Panel
        ////t.updateBreadcrumbs(); // Update Breadcrumbs in Title
        // INSERT CUSTOM WHERES HERE
        // t.customWhere.push ({ key: '', operator: '==', value: '' });

        // Get Users collections data
        await t.getTableData()

    },
};
</script>
