<template>
    <v-app id="app" style="overflow-x: hidden" class="customPageHeight">
        <alertbox style="z-index: 999999 !important;"/>
        <confirmation style="z-index: 999999 !important;"/>
        <datePicker style="z-index: 999999 !important;"/>
        <location></location>
        <fsdialog style="z-index: 99999 !important;"/>
        <pudialog style="z-index: 999999 !important;"/>
        <confirmbox style="z-index: 999999 !important;"/>
        <qrCodeGeneratorDialog></qrCodeGeneratorDialog>
        <location></location>

        <!--
        App Update Notification Bar
         - Renders a banner with message and button for when an app update is available.
        -->
        <v-banner v-if="updateExists" height="72px" app sticky class="accent noprint"
                  style="width: 100% !important; position: fixed !important; z-index: 1000 !important;">
            <v-row no-gutters class="d-flex justify-center align-center pa-3">

                <!--Label-->
                <app-text category="text-default" class="white--text">App Update Available!</app-text>

                <!--Update button-->
                <v-btn @click="refreshApp"
                       class="animate__animated animate__pulse primary animate__infinite ml-4"
                       dark
                       small
                       :height="buttonSizeDefault">
                    <app-text category="text-default">Update Now</app-text>
                </v-btn>

            </v-row>
        </v-banner>

        <!--
        App Bar
         - Renders the top AppBar with the company logo to the left and menu to the right.
         - The menu has the following options:
             - Home
            - Profile (inactive)
            - Support (inactive)
            - Install app (this is missing?)
            - Log out
            - Terms and Conditions
            - App version
         - The app bar icons should not render on the Login page
        -->
        <v-app-bar
            v-if="!['Login', 'Register', 'ResetPassword', 'OfficeDashboard', 'SiteDashboard', 'Unauthorised'].includes($route.name)"
            app class="#FFFFFF noprint" clipped-left height="64px">

            <!--Home icon-->
            <v-icon @click="MIX_go('/')" class="icons8-home ml-2" color="primary"/>

            <!--Company logo-->
            <v-img @click="MIX_go('/')" :height="buttonSizeDefault" width="50" position="center" contain
                   :src="require('@/assets/company_logo.svg')"/>

            <!--Menu-->
            <v-menu offset-y left>

                <!--Menu icon-->
                <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon color="primary" class="mr-2">icons8-person</v-icon>
                    </v-btn>
                </template>

                <!--Menu items-->
                <v-list nav dense pa-0 pr-2 ma-0>

                    <!--Home-->
                    <v-list-item class="ma-0 pa-0" @click="MIX_go('/')">
                        <v-btn class="justify-start"
                               color="white"
                               depressed
                               :height="buttonSizeDefault"
                               width=100%>
                            <v-icon class="icons8-home mr-4" color="primary"/>
                            <v-list-item-title class="darkgrey--text">Home</v-list-item-title>
                        </v-btn>
                    </v-list-item>

                    <!--Profile-->
                    <v-list-item class="ma-0 pa-0">
                        <v-btn @click="MIX_go('userProfile')" class="justify-start"
                               color="white"
                               depressed
                               :height="buttonSizeDefault"
                               width=100%>
                            <v-icon class="icons8-male-user mr-4" color="primary"/>
                            <v-list-item-title class="darkgrey--text">Profile</v-list-item-title>
                        </v-btn>
                    </v-list-item>

                    <!--Fire Roll Call-->
                    <v-list-item
                        v-if="(GET_currentUser && GET_currentUser.userLevel === 'SA') || (GET_currentUser && GET_currentUser.userAccessAndAbilities.includes('Fire Marshall'))"
                        class="ma-0 pa-0">
                        <v-btn @click="MIX_go('fireRollCall')" class="justify-start"
                               color="white"
                               depressed
                               :height="buttonSizeDefault"
                               width=100%>
                            <v-icon class="icons8-fires mr-4" color="primary"/>
                            <v-list-item-title class="darkgrey--text">Fire Roll Call</v-list-item-title>
                        </v-btn>
                    </v-list-item>

                    <!--Install app-->
                    <v-list-item v-if="showInstallBanner" @click="install()" class="ma-0 pa-0">
                        <v-btn class="justify-start"
                               color="white"
                               depressed
                               :height="buttonSizeDefault"
                               width=100%>
                            <v-icon class="icons8-download" color="darkgrey"/>
                            <v-list-item-title class="darkgrey--text">Install App</v-list-item-title>
                        </v-btn>
                    </v-list-item>

                    <!--Log out-->
                    <v-list-item class="ma-0 pa-0" v-if="$store.getters.GET_currentUser != null" @click="logout()">
                        <v-btn class="justify-start"
                               color="white"
                               depressed
                               :height="buttonSizeDefault"
                               width=100%>
                            <v-icon class="icons8-exit mr-4" color="primary"/>
                            <v-list-item-title class="darkgrey--text">Log Out</v-list-item-title>
                        </v-btn>
                    </v-list-item>

                    <!--Terms and Conditions-->
                    <v-list-item @click="MIX_go('/privacyPolicy')" class="ma-0 pa-0">
                        <v-btn class="justify-start"
                               color="white"
                               depressed
                               :height="buttonSizeDefault"
                               width=100%>
                            <v-icon class="icons8-privacy-policy mr-4" color="primary"/>
                            <v-list-item-title class="darkgrey--text">Privacy Policy</v-list-item-title>
                        </v-btn>
                    </v-list-item>

                    <!--App version-->
                    <v-list-item>
                        <v-list-item-title class="grey--text pl-2">App Version {{ LATEST_VERSION }}</v-list-item-title>
                    </v-list-item>

                </v-list>

            </v-menu>

        </v-app-bar>

        <!--
        Main Navigation (Side - Vertical)
         - Renders the vertical side navigation menu on medium-sized (and up) screens.
        -->
        <v-navigation-drawer v-if="
			$vuetify.breakpoint.mdAndUp
			&& showPageElements === true
			&& GET_currentUser.userLevel !== 'VU'
			&& !['OfficeDashboard', 'SiteDashboard', 'Unauthorised'].includes($route.name)"
                             app
                             class="primary noprint"
                             clipped
                             expand-on-hover
                             permanent
                             style="z-index: 999 !important;">
            <menuList/>
        </v-navigation-drawer>

        <!--
        Main Navigation (Top - Horizontal)
         - Renders the horizontal top navigation menu on small-sized (and down) screens.
        -->
        <v-banner app sticky class="pa-0 elevation-0 ma-0"
                  v-if="$vuetify.breakpoint.smAndDown && showPageElements === true && GET_currentUser.userLevel !== 'VU' && !['Unauthorised'].includes($route.name)">
            <v-row no-gutters>
                <v-col cols="12">

                    <v-tabs fixed-tabs show-arrows icons-and-text class="elevation-0 pa-0 ma-0"
                            v-model="menutab" hide-slider background-color="primary" color="white">

                        <v-tab v-for="(item, index2) in menu" :key="index2" :to="item.path"
                               class="white--text app-nav-tab"
                               :active-class="['Dashboard', 'StaffDirectory', 'Observations'].includes($route.name) ? 'app-nav-tab-active' : ''">

                            <app-text category="text-small" class="mt-1">{{ item.name }}</app-text>
                            <v-icon class="navicons display-1" color="white">{{ item.icon }}</v-icon>
                        </v-tab>
                    </v-tabs>

                </v-col>
            </v-row>
        </v-banner>

        <progressbar/>

        <v-main class="appbackground">
            <v-container class="ma-0 pa-0 d-flex align-start fill-height" fluid>
                <transition name="custom-classes-transition"
                            enter-active-class="animate__animated animate__fadeIn animate__faster"
                            leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                    <router-view/>
                </transition>
            </v-container>

            <!--Footer-->
            <div class="appCopyrightFooter">
                <appcopyright/>
            </div>

            <!--start of ACCESS-CODE-OVERLAY-->
            <v-overlay v-if="!authSuccess" :opacity="1" color="vindicoPurple">
                <v-dialog v-if="!authSuccess" v-model="dialogLogin" persistent style="z-index: 3000 !important;"
                          width="500">
                    <v-card flat class="login" height="350" color="vindicoPurple">
                        <v-card-title class="justify-center pt-4">
                            <v-img height="50" contain width="50%" :src="require('@/assets/vlogo-white.png')"/>
                        </v-card-title>
                        <v-divider class="white mt-2"></v-divider>
                        <v-card-text class="mt-6 mb-0 pb-0">
                            <!-- <p class="headline white--text text-left font-weight-thin my-0 py-0" >Vindico Preview</p> -->
                            <p class="body-1 white--text text-left my-0 py-2">
                                Please enter the access code you received in order to proceed to the preview.
                            </p>
                            <!-- ACCESS-CODE-INPUT-->
                            <v-text-field label="Preview Code" hide-details="auto" v-model.trim="accessCode" dark filled
                                          type="password" v-on:keyup.enter="enterAccessCode()"></v-text-field>
                            <!-- start of ERROR-MESSAGE-->
                            <v-row v-if="authCode === 0" class="justify-center ma-0 pa-0">
                                <transition name="custom-classes-transition" enter-active-class="animated flash">
									<span :class="{ 'error--text': !authSuccess }"
                                          class="subtitle1 font-weight-bold my-1">{{ authMessage }}</span>
                                </transition>
                            </v-row>
                            <!-- end of ERROR-MESSAGE-->
                        </v-card-text>
                        <v-card-actions class="justify-center pt-0 mt-6">
                            <!-- ENTER-ACCESS-CODE-BUTTON-->
                            <v-btn class="white vindicoPurple--text" large @click="enterAccessCode()">Enter</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-overlay>
            <!--end of ACCESS-CODE-OVERLAY-->
        </v-main>

    </v-app>
</template>

<script>
import progressBar from "@/modules/progressBar/progressBar_component.vue";
import PrivacyTermsConditions from "@/components/termsAndConditions.vue";
import {version as appVersionNumber} from '../package.json'

let installEvent;
export default {

    name: "App",

    components: {
        progressbar: progressBar,
        PrivacyTermsConditions,
    },

    data() {
        return {
            currentUser: [],
            LATEST_VERSION: appVersionNumber,
            // page elements
            showPageElements: false, // hide menu etc. when there's no user logged in
            showInstallBanner: false,
            menutab: [],
            menu: [
                // {name: "Home", icon: "icons8-home", path: `/`},
                {name: "Dashboard", icon: "icons8-content", path: `/dashboard`},
                {name: "Staff", icon: "icons8-people", path: `/staff-directory`},
                // {name: "Training Courses", icon: "icons8-course-assign", path: `/courses`},
                // {name: "Areas", icon: "icons8-map-marker", path: `/areas`},
                // {name: "Services", icon: "icons8-vacuum-cleaner", path: `/services`},
                // {name: "Clients", icon: "icons8-business-building", path: `/clients`},
                // {name: "Concerns", icon: "icons8-business-building", path: `/concerns`},
                {name: "Observations", icon: "icons8-eye", path: `/observations`},
                // {name: "Sites", icon: "icons8-skyscrapers", path: `/sites`},
                // {name: "Questions", icon: "icons8-ask-question", path: `/questions`},
            ],
            // * ACCESS CODE VARIABLES
            // TODO - change access password
            accessPassword: "Vindico2021!", // ? ACCESS PASSwORD
            accessCode: "", // TODO remove before go live // user input code
            dialogLogin: true, // login dialog
            authSuccess: true, // TODO change back to false before go live // * when true - closes the overlay
            authMessage: null, // message displayed to user
            authCode: null, // * 1 for correct password, 0 for incorrect

            userTypes: [],
            userType: "",
            userRoles: [],
            userRole: "",
        };
    },

    watch: {
        currentUser: {
            handler: function () {
                this.$store.commit("SET_currentUser", this.currentUser);
            },
            deep: true,
        },
    },

    methods: {
        logout() {
            this.$firebase.auth.signOut().then(() => {
                this.showPageElements = false;
                this.$router.push("/login").catch((err) => {
                    console.log("Route error: " + err);
                });
                this.$store.commit("SET_currentUser", null);
            });
        },
        install() {
            this.showInstallBanner = false;
            installEvent.prompt();
            installEvent.userChoice.then(() => {
                installEvent = null;
            });
        },
        goToContact() {
            this.$router.push("/contact-us").catch((err) => {
                console.log("Router error: " + err);
            });
        },
        enterAccessCode() {
            // * Access code validation
            var v = this;
            if (this.accessCode === this.accessPassword) {
                v.authSuccess = true; // * close the overlay
                v.authCode = 1; // * success code
                v.authMessage = "The access code was correct";
                v.accessCode = null;
            } else {
                v.authSuccess = false; // ! keep the overlay open
                v.authCode = 0; // ! error code
                v.authMessage = "The access code you have entered is incorrect";
            }
        },
    },

    async created() {
        var t = this;
        this.$firebase.auth.onAuthStateChanged((user) => {
            if (user != null) {
                t.$firebase.db
                    .collection("users")
                    .where("authId", "==", user.uid)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.docs.length === 0) {
                            t.showPageElements = false;
                        } else {
                            querySnapshot.forEach(function (doc) {
                                var document = doc.data();
                                t.currentUser = document;
                            });
                            t.showPageElements = true;
                        }
                    });
            } else {
                this.showPageElements = false;
            }
        });
    },

    mounted() {
        this.MIX_progressBar({show: false});
    },

    //         created() {
    //             window.addEventListener("beforeinstallprompt", (e) => {
    //                 e.preventDefault();
    //                 installEvent = e;
    //                 this.showInstallBanner = true;
    //             });
    //   },
};
</script>

<style>
/* The app-nav... styles below are for the tab navigation (they are active even if they're greyed out) */
.app-nav-tab {
    background: var(--v-primary);
}

.app-nav-tab:active,
.app-nav-tab:hover {
    background: var(--v-primaryLight-base);
}

.app-nav-tab-active {
    background: var(--v-accent-base);
}

/* Make the page full screen minus the appbar */
.customPageHeight {
    height: calc(100% - 64px)
}

/* Push the footer to the bottom of the page, minus the height of the footer */
.appCopyrightFooter {
    position: relative;
    top: -64px;
    padding: 0 16px
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*color: #2c3e50;*/
    color: #666666;
}

.v-banner__wrapper {
    padding: 0px !important;
    margin: 0px !important;
}

.v-banner__content {
    padding: 0px !important;
    margin: 0px !important;

}

.tile:active {
    color: #308CCB !important;
}

.v-toolbar__content {
    padding-left: 10px !important;
}

.tablecard {
    border-radius: 5px !important;
    /* width: 100% !important;
    height: 100px !important; */
}

/* .app-bar-background {
	background: rgb(48, 141, 204);
	background: linear-gradient(90deg, rgba(48, 141, 204, 1) 0%, rgba(81, 35, 121, 1) 100%);
} */

.border-left-grey {
    border-left: 3px solid #cdcdcd !important; /*//#DFDFDF; /*E0E0E0*/
}

.border-top-grey {
    border-top: 2px solid #cdcdcd !important;
}

.heading1 {
    letter-spacing: -0.015625em;
    font-size: 6rem;
    font-weight: 300;
}

.heading2 {
    letter-spacing: -0.0083333333em;
    font-size: 3.75rem;
    font-weight: 300;
}

.heading3 {
    font-size: 3rem;
    font-weight: 400;
}

.heading4 {
    letter-spacing: 0.0073529412em;
    font-size: 2.125rem;
    font-weight: 400;
}

.heading5 {
    font-size: 1.5rem;
    font-weight: 300;
}

.heading6 {
    font-size: 1.25rem;
    font-weight: 500;
}

.subtitle1 {
    letter-spacing: 0.009375rem;
    font-size: 1rem;
    font-weight: 400;
}

.subtitle2 {
    letter-spacing: 0.0071428571em;
    font-size: 0.875rem;
    font-weight: 500;
}

.body1 {
    letter-spacing: 0.03125em;
    font-size: 1rem;
    font-weight: 300;
}

.body2 {
    letter-spacing: 0.0178571429em;
    font-size: 0.875rem;
    font-weight: 400;
}

.button {
    letter-spacing: 0.0892857143em;
    font-size: 0.875rem;
    font-weight: 500;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-up')}
    .contentstart
        margin-top: 20px !important

@media #{map-get($display-breakpoints, 'xs-only')}
    .contentstart
        margin-top: 50px !important
    .bottomnav
        width: 100% !important
    .submenu
        z-index: 100 !important
        position: fixed !important
        top: 131px !important
        width: 100% !important
    .copyright
        width: calc(100% - 25px) !important

        bottom:0px !important
</style>
