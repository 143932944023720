<template>
    <div>
        <v-dialog v-model="dialog"
                  transition="dialog-bottom-transition"
                  max-width="512">

            <!--Activator-->
            <template v-slot:activator="{on, attrs}">

                <div v-on="on" :style="$vuetify.breakpoint.width < 600 && 'width: calc(100vw - 32px)'">
                    <app-btn
                        :block="$vuetify.breakpoint.width < 600"
                        color="error"
                        icon="icons8-fires"
                        label="Cancel Fire Roll Call"/>
                </div>

            </template>

            <!--Dialog content-->
            <v-card>

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text ml-2">Cancel Fire Roll Call?</app-text>

                    <v-spacer/>

                    <v-btn @click="dialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <!--Body-->
                <div class="pa-4">

                    <!--Info text-->
                    <app-text category="text-default">
                        By cancelling this Fire Role Call, you will lose all register data and nothing will be recorded.
                        <br>
                        <br>
                        Are you sure you want to cancel?
                    </app-text>

                    <!--Action buttons-->
                    <div class="d-flex mt-4">

                        <!--No button-->
                        <app-btn @click.native="dialog = false"
                                 color="success"
                                 label="No, keep"/>

                        <v-spacer/>

                        <!--Yes button-->
                        <app-btn @click.native="handleCancelRegister"
                                 color="error"
                                 label="Yes, delete"/>

                    </div>

                </div>

            </v-card>


        </v-dialog>
    </div>
</template>

<script>
export default {

    name: "fireRollCallCancelDialog",

    data: () => ({

        dialog: false,

    }),

    methods: {

        handleCancelRegister() {
            const t = this

            t.$emit('emitHandleCancelRegister')
            t.dialog = false
        }

    }
}
</script>

<style>

</style>
