import { mapActions } from 'vuex';
const confirmBox_mixins = {       
    methods: {
        ...mapActions('confirmBox_store', ['ACT_confirmBox']),
        MIX_confirmBox (payload) { this.ACT_confirmBox(payload) },
    }
}
export default {
    install (Vue, options) {
        Vue.mixin(confirmBox_mixins)
    }
};

 

  
  