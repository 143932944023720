<!--
Staff User Modal

Renders a Dialog component showing a User Card which opens to a bigger user pop up with the selected user's details
and options to either call or email them directly.
-->
<template>
    <div>
        <v-dialog max-width="512"
                  transition="dialog-bottom-transition"
                  v-model="dialog">

            <!--Activator-->
            <template v-slot:activator="{ on, attrs }">

                <!--User Card-->
                <v-card v-on="on" class="d-flex" height="96" min-width="296" width="100%">

                    <!--Image-->
                    <div v-if="showImages" class="lightgrey">

                        <!--If an image is present, render it-->
                        <v-img v-if="user.profilePicFileURL"
                               center
                               class="rounded-l"
                               :class="isGreyScale(user.swappStatus) && 'grayScaleFilter'"
                               height="96"
                               :src="user.profilePicFileURL"
                               width="96"/>

                        <!--If an image is NOT present, render an icon-->
                        <v-icon v-else
                                class="icons8-customer"
                                size="96"/>

                    </div>

                    <!--User details (Name | Ability icons | Position | Telephone | Location)-->
                    <v-row no-gutters>
                        <v-col class="d-flex flex-column justify-space-between">

                            <!--Name | Position | Ability Icons-->
                            <div class="ma-2">

                                <!--Name | Ability icons-->
                                <div class="d-flex align-start">

                                    <!--Name-->
                                    <app-text category="text-default-bold"
                                              class="mb-2"
                                              :class="isGreyScale(user.swappStatus) ? 'grey--text' : 'primary--text'">
                                        {{ user.userName }}
                                    </app-text>

                                    <v-spacer/>

                                    <!--Ability Icons-->
                                    <v-icon v-if="user.userAccessAndAbilities.includes('Fire Marshall')"
                                            class="icons8-fire ml-2" color="error" size="24"/>
                                    <v-icon v-if="user.userAccessAndAbilities.includes('First Aider')"
                                            class="icons8-clinic ml-2" color="success" size="24"/>

                                </div>

                                <!--Position-->
                                <app-text category="text-default" class="grey--text">
                                    {{ renderPositionText() }}
                                </app-text>

                            </div>

                            <!--Location label-->
                            <app-text category="text-default" class="white--text pl-2"
                                      :class="getScheduleColor(user.scheduleRenderGroup)">
                                {{ computedLocation }}
                            </app-text>

                        </v-col>
                    </v-row>

                </v-card>

            </template>

            <!--Modal Content-->
            <v-card>

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text">{{ user.userName }}</app-text>

                    <v-spacer/>

                    <v-btn @click="dialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <!--Content-->
                <div>

                    <!--Image | Buttons-->
                    <div class="d-flex align-center pa-4">

                        <!--If an image is present, render it-->
                        <div class="mr-4" style="border: 1px solid lightgrey; border-radius: 4px">
                            <v-img v-if="user.profilePicFileURL"
                                   center
                                   class="rounded"
                                   height="128"
                                   :src="user.profilePicFileURL"
                                   width="128"/>

                            <!--If an image is NOT present, render an icon-->
                            <v-icon v-else
                                    class="icons8-customer"
                                    size="128"/>
                        </div>

                        <!--Buttons (call | email)-->
                        <div v-if="user.userType !== 'Player'"
                             class="d-flex justify-center" style="width: 100%">
                            <div class="d-flex flex-column align-end">

                                <!--Call button-->
                                <v-btn @click="openTelephone(user.userTelephone)"
                                       class="mb-8" color="white" depressed :height="buttonSizeDefault">
                                    <app-text v-if="$vuetify.breakpoint.width > 380" category="text-default"
                                              class="grey--text mr-4">Call
                                    </app-text>
                                    <v-icon class="icons8-phone" color="primary" size="48"/>
                                </v-btn>

                                <!--Email button-->
                                <v-btn @click="openEmail(user.userEmail)"
                                       color="white" depressed :height="buttonSizeDefault">
                                    <app-text v-if="$vuetify.breakpoint.width > 380" category="text-default"
                                              class="grey--text mr-4">Email
                                    </app-text>
                                    <v-icon class="icons8-email" color="primary" size="48"/>
                                </v-btn>

                            </div>
                        </div>

                    </div>

                    <v-divider class="mx-4"/>

                    <!--User details (position | telephone | location)-->
                    <div class="pa-4 pb-1">

                        <!--Position-->
                        <div class="d-flex align-center mb-4">
                            <v-icon class="icons8-management mr-4" :color="iconColor" :size="24"/>
                            <app-text category="text-default">{{ renderPositionText() }}</app-text>
                        </div>

                        <!--Telephone-->
                        <div class="d-flex align-center mb-4">
                            <v-icon class="icons8-phone mr-4" :color="iconColor" :size="24"/>
                            <app-text category="text-default">{{ user.userTelephone }}</app-text>
                        </div>

                        <!--Email-->
                        <div class="d-flex align-center mb-4">
                            <v-icon class="icons8-email mr-4" :color="iconColor" :size="24"/>
                            <app-text category="text-default">{{ user.userEmail }}</app-text>
                        </div>

                        <!--Location-->
                        <div class="d-flex align-center mb-4">
                            <v-icon class="icons8-qr-code ml-n1 mr-4" :color="swappStatus(user.swappStatus)"
                                    :size="32"/>
                            <app-text v-if="user.lastLocation" category="text-default" class="ml-n1 ">
                                {{ locationText(user.swappStatus) }}
                            </app-text>
                        </div>

                        <v-divider/>

                        <!--Ability Icons-->
                        <div :class="$vuetify.breakpoint.width > 360 && 'd-flex '">
                            <div v-if="user.userAccessAndAbilities.includes('Fire Marshall')"
                                 class="d-flex my-4 mr-4">
                                <v-icon class="icons8-fire mr-4" size="32" color="error"/>
                                <app-text category="text-default">Fire Marshall</app-text>
                            </div>
                            <div v-if="user.userAccessAndAbilities.includes('First Aider')"
                                 class="d-flex my-4">
                                <v-icon class="icons8-clinic mr-4" size="32" color="success"/>
                                <app-text category="text-default">First Aider</app-text>
                            </div>
                        </div>

                    </div>

                </div>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['user', 'showImages'],

    data: () => ({
        dialog: false,
        iconSize: 20,
        iconColor: 'primary',
    }),

    computed: {

        /**
         * Computed Location
         *
         * Add a prefix to the scheduled location so it reads better in the card,
         * and also so it's easier to identify when multiple groups are greyed (swapped) out.
         *
         * @returns location - location with prefix
         */
        computedLocation() {
            const t = this
            let location = t.$props.user.todaysSchedule

            switch (t.$props.user.scheduleRenderGroup) {
                case "current":
                    location = '@ ' + location
                    break
                case "away":
                    location = 'Away visiting ' + location
                    break
                case "visitor":
                    location = 'Visiting ' + location
                    break
                case "home":
                    location = location
                    break
                case "offSite":
                    location = location
                    break
                case "off":
                    location = location
                    break
                case "holiday":
                    location = location
                    break
                default:
                    console.log('Dashboard User Card schedule render group not recognised')
            }

            return location

        },

    },

    methods: {
        /**
         * Swapp Status
         *
         * Change the colour of the QR icon based on the swapp status.
         * 1 (in) = green
         * 0 (out) = blue
         *
         * @param status - the current swapp status of the user
         * @returns {string} - colour for the swapp icon
         */
        swappStatus(status) {
            return status === 1 ? 'success' : 'primary'
        },

        /**
         * Is Grey Scale
         *
         * Set some elements to greyscale based on the user swapp status to signify not present.
         *
         * @param status - the current swapp status of the user
         * @returns {boolean} - condition to apply the greyscale filter
         */
        isGreyScale(status) {
            const t = this

            if (['CURRENT', 'AWAY', 'VISITOR'].includes(t.$props.user.scheduleRenderGroup.toUpperCase())) {
                return status === 0
            }
        },

        /**
         * Location Text
         *
         * Render either the current location name, or 'out' based on the swapp status of the user.
         *
         * @returns {string}
         */
        locationText() {
            const t = this

            return t.$props.user.swappStatus === 1 ? t.$props.user.lastLocation.locationName : '(out)'
        },

        /**
         * Render Position Text
         *
         * Return a string for the position of the user.
         * If the user is staff, return their position.
         * If the user is a visitor, return 'Visitor' in place of a position.
         *
         * @return position - String containing the user's position
         */
        renderPositionText() {
            const t = this
            let position

            if (t.$props.user.userLevel === 'VU') {
                position = 'Visitor'
            } else {
                position = t.$props.user.userJobTitle
            }

            return position
        },

        /**
         * Open Telephone
         *
         * Open the device's call service to call the user.
         *
         * @param number - the number to call
         */
        openTelephone(number) {
            window.open('tel:' + number)
        },

        /**
         * Open Email
         *
         * Open the device's email service to email the user.
         *
         * @param email - the email to send to
         */
        openEmail(email) {
            window.open('mailto:' + email)
        },

        /**
         * Get Schedule Color
         *
         * Take the schedule value and return a color for the card banner.
         * If the user has not SWAPP'd in, the color should be grey.
         *
         * @param schedule - schedule value to base the color on
         * @return {string} - vuetify color name
         */
        getScheduleColor(schedule) {
            const t = this
            let color = ''

            // If the user is not SWAPPed in, set the color to grey
            if (['CURRENT', 'AWAY', 'VISITOR'].includes(schedule.toUpperCase()) && t.$props.user.swappStatus === 0) {
                color = 'grey'
            }

            // If the user is SWAPPed in, set a color for the banner
            else {

                switch (schedule.toUpperCase()) {
                    case 'CURRENT' :
                        color = 'primary'
                        break
                    case 'AWAY' :
                        color = 'away'
                        break
                    case 'HOME' :
                        color = 'home'
                        break
                    case 'OFF' :
                        color = 'off'
                        break
                    case 'OFFSITE' :
                        color = 'offSite'
                        break
                    case 'HOLIDAY' :
                        color = 'holiday'
                        break
                    case 'VISITOR' :
                        color = 'visitor'
                        break

                    default:
                        console.log('DashboardUserModal getScheduleColor() color not recognised')
                }
            }

            return color
        }
    }
}
</script>

<style>
.grayScaleFilter {
    filter: grayscale(100%);
}
</style>
