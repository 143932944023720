<!--
Concerns

Renders the Concerns page displaying a list of concerns (table on tablets and desktops, cards on mobile).
-->
<template>
	<v-row no-gutters class="fill-height" style="height: 100% !important">

		<!--
		Left Panel
		-->
		<v-col :cols="GET_panelLayout.leftPanel" class="pa-4 ma-0">

			<!--
			Header
			-->
			<v-row class="ma-0" dense>

				<!--Page title-->
				<page-title icon="icons8-eye" pageTitle="Observations"/>

				<!--
				Filters
				-->
				<v-row dense class="d-flex ma-0 mb-4 pa-0">

					<!--Search-->
					<v-col :cols="$vuetify.breakpoint.width < 820 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 820 ? 'pa-0' : 'pa-0 pr-1'">
						<v-text-field background-color="white"
									  clearable
									  dense
									  filled
									  hide-details
									  :label="$t(`filters.search`)"
									  prepend-inner-icon="icons8-search"
									  v-model="search"/>
					</v-col>

					<!--Type-->
					<v-col v-if="$vuetify.breakpoint.lgAndUp && ['SA'].includes(GET_currentUser.userLevel)"
						   :cols="$vuetify.breakpoint.width < 820 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 820 ? 'mt-4 pa-0' : 'pa-0 px-1'">
						<v-select background-color="white"
                                  clearable
								  dense
								  filled
								  item-text="text"
								  item-value="value"
								  :items="observationTypesOptionsData"
								  hide-details
								  :label="$t(`filters.type`)"
								  v-model="filter.type"/>
					</v-col>

					<!--Sort by-->
					<v-col v-if="$vuetify.breakpoint.lgAndUp && ['SA'].includes(GET_currentUser.userLevel)"
						   :cols="$vuetify.breakpoint.width < 820 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 820 ? 'd-flex align-center mt-4 pa-0' : 'd-flex align-center pa-0 pl-1'">

						<v-select background-color="white"
								  clearable
								  dense
								  filled
								  hide-details
								  item-text="text"
								  item-value="value"
								  :items="computedHeaders"
								  :label="$t(`filters.sortBy`)"
								  v-model="sortBy">
							<template v-slot:item="data">{{ data.item.text }}</template>
							<template v-slot:selection="data">{{ data.item.text }}</template>
						</v-select>

						<!--Filer up/down button-->
						<v-btn icon class="ml-2" @click="filter.sortAsc = !filter.sortAsc">
							<v-icon
								:class="{ 'icons8-collapse-arrow': filter.sortAsc, 'icons8-expand-arrow': !filter.sortAsc }"
								class="text-h5"/>
						</v-btn>

					</v-col>

				</v-row>

				<!-- Status filters | Action buttons -->
				<v-col cols="12" class="d-flex mb-4 pa-0 align-center">

					<!--Status filters-->
					<div v-if="['SA'].includes(GET_currentUser.userLevel)">
						<v-btn-toggle active-class="white"
									  background-color="grey"
									  color="white"
									  dense
									  multiple
									  style="height: 48px"
									  v-model="filter.status">

							<!--Pending-->
							<v-btn :height="buttonSizeDefault" value="PENDING">
								<v-icon color="accent" class="icons8-circle"/>
								<app-text v-if="$vuetify.breakpoint.mdAndUp"
										  category="text-small" class="primary--text">
									{{ $t(`status.pending`) }}
								</app-text>
							</v-btn>

							<!--Resolved-->
							<v-btn :height="buttonSizeDefault" value="RESOLVED">
								<v-icon color="success" class="icons8-checkmark-yes"/>
								<app-text v-if="$vuetify.breakpoint.mdAndUp"
										  category="text-small" class="success--text">
									{{ $t(`status.resolved`) }}
								</app-text>
							</v-btn>

						</v-btn-toggle>
					</div>

					<!--Show deleted switch-->
					<div v-if="['DA'].includes(GET_currentUser.userLevel)">
						<div class="d-flex ml-8" v-if="$vuetify.breakpoint.mdAndUp">
							<v-icon class="icons8-trash display-1" color="primary"/>
							<v-switch @change="getTableData()" v-model="showDeleted" class="my-n4 pt-5 ml-4" inset
									  color="purple"></v-switch>
						</div>
					</div>

					<v-spacer/>

					<!--
					Action Buttons
					 - Add Concern | Columns | Export
					-->
					<v-btn v-if="$vuetify.breakpoint.width > 728" @click.native="openItem('', 'New', false)"
						   color="primary mr-2" depressed :height="buttonSizeDefault">
						<v-icon class="icons8-siren"/>
						<app-text v-if="$vuetify.breakpoint.width > 1" category="text-default" class="ml-2">
							New Observation
						</app-text>
					</v-btn>

					<!--Columns | Export-->
					<div v-if="['SA'].includes(GET_currentUser.userLevel)" class="d-flex align-center">

						<!--Columns-->
						<v-btn v-if="$vuetify.breakpoint.width >= 600" @click.native="columnsDialog = true"
							   color="primary mr-2" depressed :height="buttonSizeDefault">
							<v-icon class="icons8-select-column"/>
							<app-text v-if="$vuetify.breakpoint.width > 888" category="text-default" class="ml-2">
								Columns
							</app-text>
						</v-btn>

						<!--Export-->
						<v-btn v-if="$vuetify.breakpoint.width >= 600" @click.native="mix_exportDocuments"
							   color="primary" depressed :height="buttonSizeDefault">
							<v-icon class="icons8-export-csv"/>
							<app-text v-if="$vuetify.breakpoint.width > 888" category="text-default" class="ml-2">
								Export
							</app-text>
						</v-btn>

					</div>

				</v-col>

				<!--
				Add Observation
				 - Create a new Observation. Only render on devices <= 384px in width
				-->
				<v-btn v-if="$vuetify.breakpoint.width <= 728" @click.native="openItem('', 'New', false)"
					   block class="primary mb-4" :height="buttonSizeDefault">
					<v-icon block class="icons8-siren mr-4"/>
					<app-text category="text-default">New Observation</app-text>
				</v-btn>

			</v-row>

			<!--
			Table
			-->
			<v-row class="ma-0">
				<v-col v-if="$vuetify.breakpoint.lgAndUp"
					   class="tabledata pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<v-data-table
						:headers="computedHeaders"
						:items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
						:items="filteredComputedTableData"
						item-key="id"
						class="elevation-0"
						hide-default-header
						:search="search">

						<!--Headers-->
						<template v-slot:header="{ props }">
							<th v-for="(head, index) in props.headers" :key="index" :align="head.align" class="pa-4">
								<app-text category="text-small" class="grey--text">{{ head.text }}</app-text>
							</th>
						</template>

						<!--ID-->
						<template v-slot:[`item.id`]="{ item }">
							<app-text category="text-small"
									  v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
									  class="error--text d-inline-block text-truncate" style="max-width: 200px;">
								<v-icon color="error" class="icons8-trash"/>
								<app-text category="text-small">{{ item.id }}</app-text>
							</app-text>
							<app-text category="text-small" v-else class="d-inline-block text-truncate"
									  style="max-width: 200px;">{{ item.id }}
							</app-text>
						</template>

						<!--Status-->
						<template v-slot:[`item.observationStatus`]="{ item }">
							<v-icon v-if="item.observationStatus === 'RESOLVED'"
									color="success"
									class="icons8-checkmark-yes display-1 d-flex justify-start"/>
							<v-icon v-if="item.observationStatus === 'PENDING'"
									color="accent"
									class="icons8-circle display-1 d-flex justify-start"/>
						</template>

						<!--Name-->
						<template v-slot:[`item.observationName`]="{ item }">
							<app-text category="text-small"
									  v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
									  class="error--text d-inline-block text-truncate ">
								<v-icon color="error" class="icons8-trash"/>
								{{ item.observationName }}
							</app-text>
							<app-text v-else category="text-small" class="">{{ item.observationName }}</app-text>
						</template>

						<!--Type-->
						<template v-slot:[`item.observationType`]="{ item }">
							<app-text v-if="item.observationType === 'ENVIRONMENT'" category="text-small">
								Environmental
							</app-text>
							<app-text v-if="item.observationType === 'QUALITY'" category="text-small">
								Quality
							</app-text>
							<app-text v-if="item.observationType === 'SAFETY'" category="text-small">
								Safety
							</app-text>
						</template>

						<!--Site-->
						<template v-slot:[`item.observationSite`]="{ item }">
							<app-text category="text-small">
								{{ item.observationSite.siteName }}
							</app-text>
						</template>

						<!--Response Time-->
						<template v-slot:[`item.observationResponseTime`]="{ item }">
							<app-text v-if="item.observationResponseTime === 'IMMEDIATE'">Immediate</app-text>
							<app-text v-if="item.observationResponseTime === '24HOURS'">24 Hours</app-text>
							<app-text v-if="item.observationResponseTime === '3DAYS'">3 Days</app-text>
						</template>

						<!--Created Date-->
						<template v-slot:[`item.createdDateTime`]="{ item }">
							<app-text category="text-small">
								{{ $moment(item.createdDateTime).format('ddd DD MMM yy @ HH:mm') }}
							</app-text>
						</template>

						<!--Created User Data-->
						<template v-slot:[`item.createdUserData`]="{ item }">
							<app-text category="text-small">{{ item.createdUserData.userName }}</app-text>
						</template>

						<!--Modified Date-->
						<template v-slot:[`item.modifiedDateTime`]="{ item }">
							<app-text category="text-small">
								{{ $moment(item.modifiedDateTime).format('ddd DD MMM yy @ HH:mm') }}
							</app-text>
						</template>

						<!--Modified User Data-->
						<template v-slot:[`item.modifiedUserData`]="{ item }">
							<app-text category="text-small">{{ item.modifiedUserData.userName }}</app-text>
						</template>

						<!--Action-->
						<template v-slot:[`item.actions`]="{ item }">
							<v-btn @click="openItem(item.id, 'View', false)"
								   depressed class="primary--text white">Open
								<v-icon color="primary" class="icons8-forward pa-0"/>
							</v-btn>
						</template>

					</v-data-table>
				</v-col>
			</v-row>

			<!--
			Mobile Cards
			 - Instead of table for small-screened devices
			-->
			<v-row class="ma-0" no-gutters>
				<v-col v-if="$vuetify.breakpoint.mdAndDown"
					   class="tabledata pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<v-row no-gutters>
						<v-col v-for="item in filteredComputedTableData" @click="openItem(item.id, 'View', false)"
							   class="white tablecard mb-4" cols="12" xs="12" :key="item.id">
							<v-row class="d-flex align-center ma-4" no-gutters>

								<!--Status icons-->
								<v-col class="flex-grow-0 mr-4">
									<v-icon v-if="item.observationStatus === 'PENDING'"
											:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
											color="accent"
											class="icons8-circle iconbackground display-1"></v-icon>
									<v-icon v-if="item.observationStatus === 'RESOLVED'"
											:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
											color="success"
											class="icons8-checkmark-yes iconbackground display-1"/>
								</v-col>

								<!--
								Observation Details
								  - Name | Type | Date
								-->
								<v-col class="flex-grow-1">

									<!--Observation name-->
									<app-text category="text-default-bold">{{ item.observationName }}</app-text>

									<!--Observation type-->
									<app-text v-if="item.observationType === 'ENVIRONMENT'"
											  category="text-small" class="grey--text">
										Environmental
									</app-text>
									<app-text v-if="item.observationType === 'QUALITY'"
											  category="text-small" class="grey--text">
										Quality
									</app-text>
									<app-text v-if="item.observationType === 'SAFETY'"
											  category="text-small" class="grey--text">
										Safety
									</app-text>

									<!--Observation date-->
									<app-text category="text-small-bold" class="grey--text noselect">
										{{ MIX_fromNow(item.createdDateTime, 'DD-MMM-YY HH:mm', false) }}
									</app-text>

								</v-col>

								<!--Action button-->
								<v-col class="flex-grow-0">
									<v-icon @click="openItem(item.id, 'View', false)"
											class="icons8-forward" color="primary"/>
								</v-col>

							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

		</v-col>

		<!--
		Right Panel
		-->
		<transition name="custom-classes-transition"
					enter-active-class="animate__animated animate__fadeIn animate__faster"
					leave-active-class="animate__animated animate__fadeOut animate__faster"
					mode="out-in">
			<rightpanel></rightpanel>
		</transition>

		<!--
		Columns Dialog Box
		-->
		<v-dialog v-model="columnsDialog" scrollable max-width="300px">
			<v-card>

				<!--Title-->
				<app-text category="text-large" class="primary--text pa-4">{{ $t('miscHeadings.showHideColumns') }}
				</app-text>

				<v-divider/>

				<!--List items-->
				<v-card-text>
					<v-list>
						<v-list-item v-for="(header, index) in headers" :key="index">
							<app-text category="text-default">
								<v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
											:false-value="true" :true-value="false"
											:label="header.text"></v-checkbox>
							</app-text>
						</v-list-item>
					</v-list>
				</v-card-text>

				<v-divider/>

				<!--Close button-->
				<v-card-actions class="text-right">
					<v-spacer/>
					<v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>

	</v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
	name: "Observations",

	data: () => ({
		title: "Observation",
		collection: "observations",
        collectionItem: "observation",
        collectionTitle: "Observations",
        collectionItemTitle: "Observation",
        columnsDialog: false,
        customWhere: [],
        filter: {
            // Default Filters
            sortAsc: false,
            status: ['PENDING', 'RESOLVED'],
            type: '',
        },
        headers: [
            {text: 'ID', value: 'id', sortable: false, align: 'left', hidden: true, hide: false, width: '20px'},
            {
                text: 'Status',
                value: 'observationStatus',
                sortable: false,
                align: 'center',
                hidden: false,
                hide: false,
                width: '10px'
            },
            {
                text: 'Name',
                value: 'observationName',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
                width: '20px'
            },
            {
                text: 'Type',
                value: 'observationType',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
                width: '20px'
            },
            {
                text: 'Site',
                value: 'observationSite',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
                width: '20px'
            },
            {
                text: 'Response Required',
                value: 'observationResponseTime',
                sortable: false,
                align: 'center',
                hidden: false,
                hide: false,
                width: '10px'
            },
            {
                text: 'Reported By',
                value: 'createdUserData',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: true,
                width: '20px'
            },
            {
                text: 'Reported On',
                value: 'createdDateTime',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
                width: '20px'
            },
            {
                text: 'Modified',
                value: 'modifiedDateTime',
                align: 'left',
                sortable: false,
                hidden: true,
                hide: false,
                width: '20px'
            },
            {
                text: 'Modified By',
                value: 'modifiedUserData',
                align: 'left',
                sortable: false,
                hidden: true,
                hide: false,
                width: '20px'
            },
            {text: '', value: 'actions', align: 'right', sortable: false, hidden: false, width: '20px'},
        ],
        langPath: "observations",
        observationSeverities: [],
        observationTypes: [],
        search: '', // Table Search
        showDeleted: false,
        sortBy: 'createdDateTime', // Default Table Sort By
        tableData: []
    }),

	computed: {
		// Map Getters
		...mapGetters({
			GET_panelLayout: 'GET_panelLayout',
			GET_currentUser: 'GET_currentUser',
			GET_currentUserData: 'GET_currentUserData',
			GET_lookupValues: 'GET_lookupValues',
			GET_help: 'GET_help'
		}),

		// Computed Table Headers
		computedHeaders() {
			const t = this;
			const headers = t.headers;
			return (t.$filter(headers, {
				hidden: false, // filter headers based on initial hidden value
			}));
		},

		// Computed Cols
		computedCols() {
			const t = this;
			var cols
			var leftPanel = t.GET_panelLayout.leftPanel
			switch (true) {
				case (leftPanel === 12):
					cols = 6;
					break
				case (leftPanel === 6):
					cols = 12;
					break;
				default:
					cols = 12;
			}
			if (t.$vuetify.breakpoint.smAndDown) {
				cols = 12
			}
			return cols
		},

		// Computed Table Data
		computedTableData() {
			const t = this;

			let tableData = t.tableData;

			// Filter by status
            tableData = tableData.filter(observation => {
                return t.filter.status.includes(observation.observationStatus)
            })

			// Filter by type
            if (t.filter.type) {
                tableData = tableData.filter(observation => {
                    return observation.observationType === t.filter.type
                })
            }

			const tableDataList = tableData.map(e => {
				return {
					id: e.id,
					observationName: e.observationName,
					observationType: e.observationType,
					observationSite: e.observationSite,
					observationStatus: e.observationStatus,
					observationResponseTime: e.observationResponseTime,
					createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'DD-MMM-YY HH:mm'),
					createdUserData: e.createdUserData,
					modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'DD-MMM-YY HH:mm'),
					modifiedUserData: e.modifiedUserData,
				};
			});

			// Sort the Table by the Field in the Sort By Filter
			const sortResults = t.$sortBy(tableDataList, t.sortBy)
			if (!t.filter.sortAsc) {
				sortResults.reverse()
			}
			return (sortResults);
		},

        /**
         * Filtered Computed Table Data
         *
         * Take the computedTableData and return an array of observations filtered by the search string.
         *
         * @returns array - observations filtered by search string
         */
        filteredComputedTableData() {
            const t = this

            return t.computedTableData.filter(observation => observation.observationName.toLowerCase().match(t.search))

        }
    },

	methods: {
		...mapActions({
			ACT_openItem: "ACT_openObservation",
			ACT_openObservation: "ACT_openObservation",
			ACT_areas: "ACT_areas",
		}),

		// UPDATE BREADCRUMBS
		updateBreadcrumbs() {
			const t = this
			const breadcrumbs = []

			breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
			t.MIX_breadcrumbs(breadcrumbs)
		},

		// OPEN ITEM
		async openItem(id, process, popup) {
			const t = this
			const item = {}

			t.dynamicComponentKey++
			item.process = process
			item.popup = popup

			if (item.process !== 'New') {
				const itemResult = await t.MIX_readDocumentById('observations', id)
				item.data = itemResult.data
			} else {
				item.data = null
			}

			t.ACT_openItem(item)

			if (t.$vuetify.breakpoint.lgAndDown) {
				t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
				t.MIX_setPanelLayout(12, 0, false, '')
			} else {
				t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)

                // * HIDE HEADERS
				for (let i = 0; i < t.headers.length; i++) {
					if (t.headers[i].hide === true) {
						t.headers[i].hidden = true
					}
				}
			}

		},

		/**
		 * Get Table Data
		 *
		 * Get all Concerns data from the database and add it to the tableData array for rendering.
		 * A 'Staff User' can only view their own Concerns, but a 'Staff Admin' can view all the concerns.
		 *
		 * @returns {Promise<void>}
		 */
		async getTableData() {
			const t = this

			// Get Concerns collection data
			let collection = t.$firebase.db.collection('observations')

			// If the user is a Staff User (SU), and
			if (['SU'].includes(t.GET_currentUser.userLevel) &&
				JSON.stringify(t.customWhere) !== '[]') {

				// Apply the custom where clause
				const customWhereLength = t.customWhere.length
				for (let i = 0; i < customWhereLength; i++) {
					const query = {
						field: t.customWhere[i].key,
						operator: t.customWhere[i].operator,
						value: t.customWhere[i].value
					}
					collection = collection.where(query.field, query.operator, query.value)
				}
			}

			collection.onSnapshot(querySnapshot => {

				// Clear the tableData to avoid duplication
				t.tableData = []

				// Iterate over the collection
				querySnapshot.forEach(doc => {

					const document = doc.data()
					document.id = doc.id

					// If the document is not marked as deleted, add it to the tableData array
					if (!document.hasOwnProperty('delete')) {
						t.tableData.push(document)
					} else if (t.showDeleted) {
						t.tableData.push(document)
					} else {
						// Do not Show Concern
					}
				})
			})
		},

	},

    watch: {
        GET_currentUser: {
            handler() {
                this.getTableData()
            }, deep: true
        },

        GET_panelLayout: {
            handler() {
                const t = this

                if (t.GET_panelLayout.show === false) {
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = false
                        }
                    }
                } else {
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = true
                        }
                    }
                }
            }, deep: true
        }
    },

	async mounted() {
		const t = this

		t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
		t.updateBreadcrumbs(); // Update Breadcrumbs in Title

        // Additional WHERE Clauses for Snapshot
		// TODO customer where should be only for user when not admin
		t.customWhere.push({key: 'createdUserData.userId', operator: '==', value: t.GET_currentUserData.userId});

		t.getTableData(); // Get Table Data
		await t.MIX_sites(); // UPDATE SITES LIST IN STORE

		// * AUTO OPEN SITE IF PATH CONTAINS SITEID
		if ((window.location.pathname !== `/${t.collection}` && window.location.pathname !== `/${t.collection}/`)) {
			const urlPath = window.location.pathname.split('/');
			const id = urlPath[2]
			if (id === 'new') {
				t.openItem('', 'New', false)
			} else {
				t.openItem(id, 'View', false)
			}
		}

	},
}
</script>
