import Vue from "vue";
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

const mixin = {
    data: () => ({
        buttonSizeDefault: 48,

        // Lookup Values for selection fields
        answersOptionsData: [
            'Yes',
            'No',
            'N/A'
        ],
        correctAnswersOptionsData: [
            'Yes',
            'No',
            'N/A'
        ],
        extendedScheduleOptionsData: [
            'Annual Leave',
            'Working from Home'
        ],
        questionTypesOptionsData: [
            'Covid'
        ],
        responseTimesOptionsData: [
            'Immediate',
            '24 Hours',
            '3 Days'
        ],
        severitiesOptionsData: [
            'Hazard',
            'Near Miss',
            'Safe Act/Unsafe Act'
        ],
        observationTypesOptionsData: [
            'Environmental',
            'Quality',
            'Safety'
        ],
        userCategoriesOptionsData: [
            'Office Staff',
            'Site Staff',
            'Visitors'
        ],
        userRolesOptionsData: [
            {text: "Admin", value: "Admin"},
            {text: "User", value: "User"},
        ],
        userStatusOptionsData: [
            {text: "Pending", value: "PENDING"},
            {text: "Approved", value: "APPROVED"},
            {text: "Suspended", value: "SUSPENDED"},
            {text: "Archived", value: "ARCHIVED"},
        ],
        userTypesOptionsData: [
            {text: "Developer", value: "Developer"},
            {text: "Staff", value: "Staff"},
        ],
        userAccessAbilitiesOptionsData: [
            'Fire Marshall',
            'First Aider',
            'Sign in from Desktop',
        ],

    }),
    methods: {
        ...mapActions([
            'ACT_mobileNav',
            'ACT_breadcrumbs',
            'ACT_panelLayout',
            'ACT_openStaffDirectory',
            'ACT_openConcern',
            'ACT_openTrainingCourse',
            'ACT_openClients',
            'ACT_openSites',
            'ACT_openQuestion',
            'ACT_trainingCourses',
            'ACT_lookupValues',
            'ACT_sites',
        ]),
        // * Set mobile menu
        MIX_mobileNav(payload) {
            this.ACT_mobileNav(payload);
        },

        // * PANEL LAYOUT
        MIX_setPanelLayout(leftPanel, rightPanel, show, title, dynamicComponent) {
            this.ACT_panelLayout({ leftPanel: leftPanel, rightPanel: rightPanel, show: show, title: title, dynamicComponent: dynamicComponent });
        },

        MIX_breakpoint() {
            return (this.$vuetify.breakpoint.name);
        },

        MIX_itemsPerPage: function(breakpoint) {
            var result = 5;
            switch(breakpoint) {
                case 'xs': result = 1; break;
                case 'sm': result = 5; break;
                case 'md': result = 10; break;
                case 'lg': result = 10; break;
                case 'xl': result = 10; break;
                default: result = 5; break;
            }
           return result;
        },

        // * VIEW RELATED MIXINS
        MIX_closeView() {
            this.MIX_puDialog(false, '', '', '');
            this.MIX_fsDialog(false, ''); // Close Full Screen Dialog
            // this.ACT_panelLayout({ leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: '' });
        },

        // * Capitalize JS String
        MIX_capitalizeText(text) {
            var capitalized = text.toLowerCase();
            capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
            return capitalized;
        },

        // * MIX BACK
        MIX_back() {
            this.$router.go(-1);
        },

        // * CHANGE ROUTE FUNCTION
        MIX_go(path) {
            this.$router.push(path).catch((err) => {
                console.log("Route error: " + err);
            });
        },

        // * FORMAT DATE TIME
        MIX_formatDateTime: function(date, formatIn, formatOut) {
            if ((date !== '') && (date !== null) && (date !== undefined)) {
                return moment(date, formatIn).format(formatOut);
            } else {
                return ''
            }
        },


        MIX_exportDocuments(headers, fileTitle, formatExport) {
            var items = formatExport;
            if (headers && items[0] != headers) {
                items.unshift(headers);
            }
            var jsonObject = JSON.stringify(items);
             // convert object to csv
             var array = typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;
             var str = "";
             for (var i = 0; i < array.length; i++) {
                 var line = "";
                 for (var index in array[i]) {
                 if (line != "") line += ",";
                 line += array[i][index];
                 }
                 str += line + "\r\n";
             }
            var csv = str
            var exportedFilenmae = fileTitle + ".csv" || "export.csv";
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                }
            }
        },
        // * sort by createdDateTime
        MIX_sortByCreatedDateTime(toSortObject) {
            var t = this;
            return toSortObject.sort(function (left, right) {
                return t.$moment(right.createdDateTime, "x").diff(t.$moment(left.createdDateTime, "x"))
            });
        },

        // MIXIN TO LOAD DATABASE DATA INTO LOOKUP TABLES STORE
        MIX_lookupTables: function() {
            var t = this;
            return new Promise(async function(resolve) {
                var lookupTablesResult = await t.MIX_readDocuments('lookupTables');
                if (lookupTablesResult.code === 1) {
                    t.ACT_lookupTables(lookupTablesResult.data);
                } else {
                    console.log('ERROR with lookupTablesResult \n' + JSON.stringify(lookupTablesResult, null, 2))
                }
                resolve();
            })
        },
        // MIXIN TO LOAD DATABASE DATA INTO LOOKUP VALUES STORE
        MIX_lookupValues: function() {
            var t = this;
            return new Promise(async function(resolve) {
                // GET LOOKUP VALUES
                var lookupValuesResult = await t.MIX_readDocuments('lookupValues');
                if (lookupValuesResult.code === 1) {
                    t.ACT_lookupValues(lookupValuesResult.data);
                } else {
                    console.log('ERROR with lookupValuesResult \n' + JSON.stringify(lookupValuesResult, null, 2))
                }
                resolve();
            })
        },

        // * Page Details
        MIX_breadcrumbs(breadcrumbs) {
            this.ACT_breadcrumbs(breadcrumbs);
        },

        // TRAINING COURSES
        MIX_trainingCourses: function() {
            var t = this;
            return new Promise(async function(resolve) {
                var courseResult = await t.MIX_readDocuments('courses');
                if (courseResult.code === 1) {
                    let courses = courseResult.data.map(({id, courseName, courseLength, courseValidity}) => { return {id, courseName, courseLength, courseValidity}  });
                    t.ACT_trainingCourses(courses);
                } else {
                    t.MIX_alert(courseResult.code, courseResult.message, courseResult.data, courseResult.error);
                }
                resolve();
            })
        },

        // TO DELETE COMMENTS/LOCATIONS/CONCERNS FROM SUBARRAYS
        MIX_deleteCollectionArray(id, item, collection, parentCollection, parentId){
            var name = '';
            switch (collection) {
                case 'comments':
                    name = item.comment;
                    break;
                case 'locations':
                    name = item.locationName;
                    break;
            }
            var title = 'Delete ' + name;
            var message = 'Are you sure you want to delete the item ' + name + '?';
            var actionDetails = {
                actionName: 'delete',
                collection: collection, // to add delete flag to doc from collection
                itemId: id,
                parentCollection: parentCollection, // to remove from the item id from the collection
                parentId: parentId,
            }

            this.MIX_showConfirmation(true, title, message, 290, "YES", "NO", actionDetails);
        },

        // SITES
        MIX_sites: function() {
            var t = this;
            return new Promise(async function(resolve) {
                var sitesResult = await t.MIX_readDocuments('sites');
                if (sitesResult.code === 1) {
                    let sites = sitesResult.data.map((site) => {
                        return {
                            'siteId': site.id,
                            'siteName': site.siteName,
                            'siteType': site.siteType,
                            'sitePostcode': site.sitePostcode
                        }
                    });
                    t.ACT_sites(sites);
                } else {
                    t.MIX_alert(sitesResult.code, sitesResult.message, sitesResult.data, sitesResult.error);
                }
                resolve();
            })
        },

        MIX_generateId() {
            return "xxxxyxxxyxxxxxxxxx".replace(/[xy]/g, function(c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },

        /**
         * From Now
         *
         * Takes a date, format and ago parameters and returns a string containing the amount of time since creating the concern.
         *
         * @param date - the concern creation date
         * @param formatIn - the format of the creation date
         * @param ago - boolean to toggle the 'ago' keyword from the returned string
         * @returns {string} - the time since concern creation e.g. '1 hour'
         */
        MIX_fromNow: function(date, formatIn, ago) {
            return moment(date, formatIn).fromNow(ago);
        },

    }
}

export default {
    install (Vue, options) {
      Vue.mixin(mixin)
    }
  }
