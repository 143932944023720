<template>
	<div style="width: 100%">
		<home/>
	</div>
</template>

<script>
// @ is an alias to /src
import home from '@/components/home.vue'

export default {
	name: 'Home',
	components: {
		home
	}
}
</script>
