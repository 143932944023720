<template>
    <v-expansion-panels v-if="GET_currentUser.userLevel !== 'VU'"
                        class="rounded-lg pa-0 mt-4" flat>
        <v-expansion-panel>

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex">

                    <!--Icon-->
                    <v-icon class="icons8-schedule ma-n1" color="primary" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">
                        Schedule
                    </app-text>

                </div>

                <!--Text-->
                <app-text v-if="!scheduleError" category="text-default-bold" class="mt-4">
                    <span class="font-weight-regular">{{ computedTomorrowsSchedulePrefix }} - </span>{{ tomorrowsScheduleData }}
                </app-text>
                <app-text v-else category="text-default" class="mt-4">
                    {{ scheduleError }}
                </app-text>

            </v-expansion-panel-header>

            <!--Mon-Fri schedule-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Mon-Fri-->
                <div style="display: grid; grid-template-columns: auto 1fr;">
                    <span class="grey--text mr-4">Mon:</span>{{ scheduleData.monday }}<br>
                    <span class="grey--text">Tue:</span>{{ scheduleData.tuesday }}<br>
                    <span class="grey--text">Wed:</span>{{ scheduleData.wednesday }}<br>
                    <span class="grey--text">Thu:</span>{{ scheduleData.thursday }}<br>
                    <span class="grey--text">Fri:</span>{{ scheduleData.friday }}<br>
                </div>

                <!--Update Button-->
                <div class="mt-4 text-right">

                    <app-btn @click.native="MIX_go('/userSchedule')"
                             color="primary"
                             label="Update Schedule"/>

                </div>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {

    name: "userScheduleHomePageCard",

    props: ['scheduleError', 'tomorrowsScheduleData', 'scheduleData'],

    computed: {

        /**
         * Computed Tomorrows Schedule Prefix
         *
         * Return either 'Tomorrow', or 'Monday' as a prefix for the next day's location, based on the day today.
         *
         * @returns {string} prefix to render
         */
        computedTomorrowsSchedulePrefix() {
            const t = this
            let prefix

            // Get today's day name
            const dayToday = t.$moment().format('dddd')

            // Return the correct prefix based on the day
            return prefix = dayToday === 'Friday' ? 'Monday' : 'Tomorrow'
        },

    }
}
</script>
