<template>
    <div>

        <!--Header-->
        <div class="d-flex align-center pa-4">

            <!--Title | Created-->
            <div>

                <!--Title-->
                <app-text category="text-medium">{{ form.title }}</app-text>

                <!--Created date-->
                <app-text category="text-small" class="grey--text">
                    {{ $moment(Number(form.createdDateTime)).format('dddd DD MMMM YYYY @ HH:mm') }}
                </app-text>

            </div>

            <v-spacer/>

            <!--Action buttons-->
            <div>

                <!--Edit-->
                <v-icon @click="editDocument"
                        class="icons8-edit ml-4"
                        :class="isFormReadOnly ? 'frc-icon' : 'frc-icon-active'"
                        :color="isFormReadOnly ? 'warning' : 'white'"
                        size="32"/>

                <!--Delete-->
                <!--<v-icon class="icons8-trash ml-4"-->
                <!--        color="error"-->
                <!--        size="32"/>-->

            </div>

        </div>

        <!--Body-->
        <div>

            <!--Tabs-->
            <v-tabs v-model="tabs" :height="buttonSizeDefault">

                <v-tabs-slider color="accent"/>
                <v-tab>Overview</v-tab>

            </v-tabs>

            <!--Tab content-->
            <v-tabs-items class="appbackground" v-model="tabs">
                <v-tab-item>

                    <!--Counters | Form | Register-->
                    <div class="pa-4">

                        <!--Counters-->
                        <div class="frcr-counter-container mt-0">

                            <!--Duration-->
                            <!--Not for small screens-->
                            <div v-if="$vuetify.breakpoint.width > 424" class="ffrcr-counter">
                                <app-text category="text-small" class="grey--text">Time Taken</app-text>
                                <app-text category="text-default" class="grey--text">
                                    {{ computedTimeTaken }}
                                </app-text>
                            </div>

                            <v-spacer v-if="$vuetify.breakpoint.width > 424"/>

                            <v-divider v-if="$vuetify.breakpoint.width > 424" vertical/>

                            <!--In-->
                            <div class="ffrcr-counter">
                                <app-text category="text-small" class="grey--text">IN</app-text>
                                <app-text category="text-default" class="grey--text">
                                    {{ computedUsersSwappedIn }}
                                </app-text>
                            </div>

                            <v-divider vertical/>

                            <!--Out-->
                            <div class="ffrcr-counter">
                                <app-text category="text-small" class="grey--text">OUT</app-text>
                                <app-text category="text-default" class="grey--text">
                                    {{ computedUsersSwappedOut }}
                                </app-text>
                            </div>

                            <v-divider vertical/>

                            <!--Total-->
                            <div class="ffrcr-counter">
                                <app-text category="text-small" class="grey--text">Total</app-text>
                                <app-text category="text-default" class="grey--text">
                                    {{ computedUsersTotal }}
                                </app-text>
                            </div>

                        </div>

                        <!--Duration-->
                        <!--Small screens only-->
                        <div v-if="$vuetify.breakpoint.width <= 424" class="frcr-counter-container mt-4">
                            <div class="ffrcr-counter">
                                <app-text category="text-small" class="grey--text">Time Taken</app-text>
                                <app-text category="text-default" class="grey--text">
                                    {{ computedTimeTaken }}
                                </app-text>
                            </div>
                        </div>

                        <!--Form-->
                        <v-form class="mt-4">

                            <!--Title | Type | Notes-->
                            <v-row no-gutters>

                                <!--Title-->
                                <v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
                                    <v-text-field background-color="white"
                                                  class="mb-4 rounded-lg"
                                                  :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                                  :disabled="isFormReadOnly"
                                                  :error="errors.title"
                                                  filled
                                                  flat
                                                  hide-details
                                                  label="Title"
                                                  :outlined="!isFormReadOnly"
                                                  solo
                                                  v-model="form.title"/>
                                </v-col>

                                <!--Type-->
                                <v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12" md="6">
                                    <v-select background-color="white"
                                              class="rounded-lg mb-4"
                                              :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                                              :disabled="isFormReadOnly"
                                              :error="errors.type"
                                              filled
                                              flat
                                              hide-details
                                              :items="['Drill', 'Emergency']"
                                              label="Type"
                                              :outlined="!isFormReadOnly"
                                              solo
                                              v-model="form.type"/>
                                </v-col>

                                <!--Notes-->
                                <v-col cols="12">
                                    <v-textarea background-color="white"
                                                class="rounded-lg"
                                                :disabled="isFormReadOnly"
                                                :error="errors.notes"
                                                filled
                                                flat
                                                hide-details
                                                label="Notes"
                                                :outlined="!isFormReadOnly"
                                                solo
                                                v-model="form.notes"/>
                                </v-col>

                            </v-row>

                        </v-form>

                        <!--User list-->
                        <div class="frc-userList">
                            <div v-for="user in computedUserList">

                                <div class="d-flex">
                                    <v-icon class="icons8-checkmark-yes mr-2" color="success lighten-2" size="24"/>
                                    <app-text category="text-default" class="grey--text">{{ user.userName }}</app-text>
                                </div>

                            </div>
                        </div>

                        <!--Save button-->
                        <div class="d-flex">
                            <v-spacer/>
                            <app-btn @click.native="validateForm"
                                     :block="$vuetify.breakpoint.width < 600"
                                     class="mt-4"
                                     color="success"
                                     icon="icons8-save"
                                     label="Update Fire Roll Call"/>
                        </div>

                    </div>

                </v-tab-item>
            </v-tabs-items>

        </div>

        <v-divider/>

        <!--Meta data-->
        <div class="pa-4">
            <app-text category="text-small" class="grey--text">
                Modified by:
                {{ form.modifiedUserData.userName }}
                -
                {{ $moment(Number(form.modifiedDateTime)).format('dddd DD MMMM YYYY @ HH:mm') }}
            </app-text>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "fireRollCall",

    data: () => ({

        errors: {
            title: false,
            type: false,
            notes: false,
        },
        form: {
            id: '',
            timeTaken: 0,
            title: '',
            type: '',
            notes: '',
            checkedUsers: [],

            modifiedDateTime: '',
            modifiedUserData: '',
        },
        isFormReadOnly: true,
        tabs: '',

    }),

    computed: {
        ...mapGetters({
            GET_openItem: "GET_openFireRollCall",
        }),

        computedUserList() {
            const t = this

            return t.form.checkedUsers.sort((a, b) => {
                return a.userName > b.userName ? 1 : -1
            })
        },

        computedUsersSwappedIn() {
            const t = this

            return t.form.checkedUsers.filter(user => user.swappStatus === 1).length
        },

        computedUsersSwappedOut() {
            const t = this

            return t.form.checkedUsers.filter(user => user.swappStatus === 0).length
        },

        computedUsersTotal() {
            const t = this

            return t.form.checkedUsers.length
        },

        computedTimeTaken() {
            const t = this

            const timeInMillis = new Date(1000 * Math.round(t.form.timeTaken / 1000))
            return `
                ${timeInMillis.getUTCHours()} h :
                ${('0' + timeInMillis.getUTCMinutes()).slice(-2)} m :
                ${('0' + timeInMillis.getUTCSeconds()).slice(-2)} s`
        },

    },

    methods: {

        validateForm() {
            const t = this

            !t.form.title.trim() ? t.errors.title = true : t.errors.title = false
            !t.form.type ? t.errors.type = true : t.errors.type = false
            !t.form.notes.trim() ? t.errors.notes = true : t.errors.notes = false

            if (!Object.values(t.errors).includes(true)) {
                t.updateDocument()
            }
        },

        editDocument() {
            const t = this

            if (t.isFormReadOnly) {
                t.isFormReadOnly = false
            } else {
                t.isFormReadOnly = true
                t.refreshDocument()
            }

        },

        async refreshDocument() {
            const t = this

            const collection = t.$firebase.db.collection('fireRollCall').doc(t.form.id)
            const doc = await collection.get()

            if (doc.exists) {
                t.form = {id: doc.id, ...doc.data()}
            }
        },

        async updateDocument() {
            const t = this

            await t.MIX_updateDocument('fireRollCall', t.form)

            t.isFormReadOnly = true
        },

    },

    async mounted() {
        const t = this

        const openDocument = await t.GET_openItem
        t.form = openDocument.data

    }

}
</script>

<style>
.frc-icon {
    border-radius: 50%;
    color: white;
    padding: 8px;
}

.frc-icon-active {
    background: orange;
    border-radius: 50%;
    color: white;
    padding: 8px;
}

.frc-userList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 16px;

    background: white;
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px;
}
</style>
