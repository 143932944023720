<template>

    <v-btn
        :block="block"
        class="rounded-lg"
        :color="color"
        :dark="dark"
        depressed
        :disabled="disabled"
        :height="height ? height : buttonSizeDefault">

        <v-icon v-if="icon" :class="icon" class="mr-2"/>

        <app-text category="text-default">{{ label }}</app-text>

    </v-btn>

</template>

<script>
export default {

    props: ['block', 'color', 'dark', 'disabled', 'icon', 'label', 'height'],

    name: "appBtn",

}
</script>

<style scoped>

</style>
