<!--
Site Dashboard

Renders a dashboard displaying staff who are IN and DUE to the current site.
A site can be selected by adding the site name (as it appears in the document siteName field), as a URL parameter.

E.g. To render the Head Office: https://scott.swapp.work/siteDashboard?site=Head Office

The complete set of url parameters include:
- site (string - must be the site name as it appears in Sites) - e.g. site=Head Office (no default)
- away (boolean) - toggles the visibility of users 'away' users - e.g. away=false (default: true)
- header (boolean) - toggles the visibility of the header - e.g. header=false (default: true)
- images (boolean) - toggles the visibility of the card images - e.g. images=false (default: true)

The data is generated from user schedules, which are grouped in to the following schedule types:
 - Current site (current)
 - Away from site (away)
 - Working from Home (home)
 - Holiday (holiday)
 - Visitors (visitor)
-->
<template>
    <v-row class="fill-height pa-0 ma-0" style="min-width: 100%" no-gutters>
        <v-col>

            <!--
            Header
             - Left side - Site details
             - Right side - DateTime | Weather forecast
            -->
            <v-row v-if="showHeader" class="pa-4" no-gutters>

                <!--Left side-->
                <div class="d-flex align-center">

                    <!--Logo-->
                    <v-img v-if="$vuetify.breakpoint.width >= 600" :src="require('@/assets/company_logo.svg')"
                           class="ml-n4" contain min-height="48" max-height="112"/>

                    <!--Site details-->
                    <div class="d-flex flex-column justify-space-around">

                        <!--Site name-->
                        <app-text category="text-xxlarge-bold" class="primary--text">
                            {{ siteData.siteName }}
                        </app-text>

                        <!--Site address-->
                        <div class="d-flex">

                            <!--Address line 1-->
                            <app-text category="text-medium" class="darkgrey--text mr-2">
                                {{ renderSiteAddress }}
                            </app-text>

                        </div>

                    </div>

                </div>

                <v-spacer/>

                <!--Right side (DateTime | Weather forecast)-->
                <div :class="$vuetify.breakpoint.width >= 1366
                                ? 'd-flex justify-end align-center'
                                : 'd-flex justify-center mt-4'"
                     :style="$vuetify.breakpoint.width < 1366 && 'width: 100%'">

                    <!--
                    Current date/time
                     - Render the current date and time as a card
                    -->
                    <div v-if="$vuetify.breakpoint.width > 832">
                        <v-card flat width="174" height="112" class="pa-2 d-flex flex-column align-center">

                            <!--Time-->
                            <app-text category="text-xxlarge" class="primary--text pb-3">
                                {{ timeNow }}
                            </app-text>

                            <!--Date-->
                            <app-text category="text-default" class="grey--text pb-0">
                                {{ $moment().format("ddd D-MMM-YYYY") }}
                            </app-text>

                        </v-card>
                    </div>

                    <!--
                    Weather cards
                     - Render a weather tile for the next 5 day forecast
                    -->
                    <div v-if="$vuetify.breakpoint.width > 632" v-for="forecast in fiveDayForecast">
                        <dashboard-weather-card :forecast="forecast"/>
                    </div>

                </div>

            </v-row>

            <!--Page break-->
            <v-row v-if="showHeader" class="primary ma-0" style="height: 8px"/>

            <!--
            Main Body
             - Personnel in/due - Full size (> 600 width)
            -->
            <div v-if="$vuetify.breakpoint.width >= 600">

                <!--Current location (current)-->
                <div v-if="scheduleTypeCurrentSite.length"
                     :class="showImages ? 'dshbrd-cardGrid' : 'dshbrd-cardGrid-small'">
                    <div v-for="user in scheduleTypeCurrentSite" style="width: 100%">
                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                    </div>
                </div>

                <!--Out on Site (away)-->
                <div v-if="showAllScheduleGroups && scheduleTypeAwayFromSite.length"
                     :class="showImages ? 'dshbrd-cardGrid' : 'dshbrd-cardGrid-small'">
                    <div v-for="user in scheduleTypeAwayFromSite" style="width: 100%">
                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                    </div>
                </div>

                <!--Working from Home (home)-->
                <div v-if="showAllScheduleGroups && scheduleTypeWorkingFromHome.length"
                     :class="showImages ? 'dshbrd-cardGrid' : 'dshbrd-cardGrid-small'">
                    <div v-for="user in scheduleTypeWorkingFromHome" style="width: 100%">
                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                    </div>
                </div>

                <!--Annual Leave (holiday)-->
                <div v-if="showAllScheduleGroups && scheduleTypeHoliday.length"
                     :class="showImages ? 'dshbrd-cardGrid' : 'dshbrd-cardGrid-small'">
                    <div v-for="user in scheduleTypeHoliday" style="width: 100%">
                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                    </div>
                </div>

                <!--Visitor (visitor)-->
                <div v-if="showAllScheduleGroups && scheduleTypeVisitor.length"
                     :class="showImages ? 'dshbrd-cardGrid' : 'dshbrd-cardGrid-small'">
                    <div v-for="user in scheduleTypeVisitor" style="width: 100%">
                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                    </div>
                </div>

            </div>

            <!--
            Main Body
             - Personnel in/due - Tabbed layout (<= 600 width) ---- MOBILE
            -->
            <div v-if="$vuetify.breakpoint.width < 600">

                <!--Tabs-->
                <v-tabs fixed-tabs v-model="tab">
                    <v-tab>Today</v-tab>
                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tab" class="lightgrey">

                    <!--Users in today-->
                    <v-tab-item>
                        <div class="flex-column">
                            <div class="ma-4">

                                <!--Head Office-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeCurrentSite" style="width: 100%">
                                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                                    </div>
                                </div>

                                <!--Out on Site (away)-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeAwayFromSite" style="width: 100%">
                                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                                    </div>
                                </div>

                                <!--Working from Home-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeWorkingFromHome" style="width: 100%">
                                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                                    </div>
                                </div>

                                <!--Holiday-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeHoliday" style="width: 100%">
                                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                                    </div>
                                </div>

                                <!--Visitor-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeVisitor" style="width: 100%">
                                        <dashboard-user-modal :user="user" :showImages="showImages"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </v-tab-item>

                </v-tabs-items>

            </div>

        </v-col>
    </v-row>
</template>

<script>
import dashboardWeatherCard from "./dashboardWeatherCard/dashboardWeatherCard";
import dashboardUserModal from "./dashboardUserModal/dashboardUserModal";
import {mapGetters} from "vuex";

export default {
    name: "DashboardNew",

    data: () => ({
        title: "DashboardNew",
        fiveDayForecast: [],
        scheduleData: [],
        scheduleTypeAwayFromSite: [], // away
        scheduleTypeCurrentSite: [], // current
        scheduleTypeHoliday: [], // holiday
        scheduleTypeVisitor: [], // visitor
        scheduleTypeWorkingFromHome: [], // home
        showAllScheduleGroups: false,
        showHeader: true,
        showImages: true,
        siteData: {},
        userData: [],
        userScheduleData: [],
        tab: null,
        timeNow: '',
        weatherData: {},
    }),

    components: {
        dashboardUserModal,
        dashboardWeatherCard,
    },

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),

        /**
         * Get SWAPPed In Users
         *
         * @return swappedInUsers - an array containing the currently swapped in users
         */
        getSwappedInUsers() {
            const t = this

            const swappedInUsers = t.userData.filter(user => user.swappStatus)
            t.userData = swappedInUsers

            return swappedInUsers
        },

        /**
         * Render Site Address
         *
         * Check for the presence of the different elements of the site address (line 1, line 2, town),
         * and return an address string when complete.
         *
         * @return siteAddress - the elements of the site address to render
         */
        renderSiteAddress() {
            const t = this
            let siteAddress = ''

            if (t.siteData?.siteAddress?.addressLine1) {
                siteAddress += t.siteData.siteAddress.addressLine1
            }

            if (t.siteData?.siteAddress?.addressLine2) {
                siteAddress += ', ' + t.siteData.siteAddress.addressLine2
            }

            if (t.siteData?.siteAddress?.town) {
                siteAddress += ', ' + t.siteData.siteAddress.town
            }

            return siteAddress
        }
    },

    methods: {

        /**
         * Decode URL Query String
         *
         * Strip the SITE parameter from the URL and call for the site data from the collection for this Dashboard.
         * Strip the AWAY parameter from the URL and display 'away' users based on this condition.
         * Strip the HEADER parameter from the URL and toggle the visibility of the header based on this condition.
         * Strip the IMAGES parameter from the URL and toggle the visibility of the images on the user cards, and
         * make the cards slightly smaller to fit six across.
         */
        decodeUrlQueryString: async function () {
            const t = this

            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)

            // Site - (string) - the site to show users for. This must be the same as the db site > siteName
            await t.getSiteCollectionData(urlParams.get('site'))

            // away - (boolean) - toggle visibility of 'away users'
            // If true -> Show everyone
            // If false -> Only show those in/due the selected site
            urlParams.get('away') === 'false' ? t.showAllScheduleGroups = false : t.showAllScheduleGroups = true

            // header - (boolean) - toggle visibility of the header
            urlParams.get('header') === 'false' ? t.showHeader = false : t.showHeader = true

            // images - (boolean) - toggle visibility of card images
            // If true -> show cards with images. Span five cards across
            // If false -> show cards without images. Toggle grid class to span six cards across
            urlParams.get('images') === 'false' ? t.showImages = false : t.showImages = true

        },

        /**
         * Get Site Collection (single site)
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        getSiteCollectionData: async function (currentSite) {
            const t = this

            // Get the site data for the current site
            t.$firebase.db.collection('sites')
                .where('siteName', '==', currentSite)
                .get()
                .then(querySnapshot => {

                    querySnapshot.forEach(doc => {

                        t.siteData = doc.data()

                    })
                })
        },

        /**
         * Get Time
         *
         * Get and set the current time for the clock.
         * This is started in mount() and stopped in beforeDestroy().
         */
        getTime() {
            const t = this

            t.timeNow = t.$moment().format("HH:mm")
        },

        /**
         * Get Users Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        getUsersCollectionData: async function () {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.userData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.userData.push(document)
                    }
                })

                t.filterStaffBySite(t.userData)
            })
        },

        /**
         * Filter Staff By Site
         *
         * Filter all users by the site they're usually based at.
         * Calls getUserScheduleCollectionData() so the component re-renders if the user snapshot changes.
         */
        filterStaffBySite(userData) {
            const t = this

            // t.userData = userData.filter(user => user.usualSite === t.siteData.siteName)

            this.getUserScheduleCollectionData()
        },

        /**
         * Get User Schedule Collection Data
         *
         * Clear the data to avoid duplications.
         * Fetch all the data from the Schedule collection.
         * Iterate over the collection and push documents to the scheduleData array.
         *
         * @returns {Promise<void>}
         */
        getUserScheduleCollectionData: async function () {
            const t = this

            const collection = t.$firebase.db.collection('schedule')
            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.scheduleData = []

                // Iterate over the collection and add the documents to the data array
                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    t.scheduleData.push(document)
                })

                // Call to link user and schedule data
                t.linkUserAndScheduleData()
            })
        },

        /**
         * Link User and Schedule Data
         *
         * Iterate over the User and Schedule data and match them by ID.
         * If they match, merge them into a new temporary object and push it to the userScheduleData array for rendering.
         */
        linkUserAndScheduleData() {
            const t = this
            let tempObj = {}

            // Rest the data to avoid duplications
            t.userScheduleData = []

            t.userData.forEach(user => {
                t.scheduleData.forEach(schedule => {

                    // Match the user to their schedule
                    if (user.id === schedule.id) {

                        // Join the user and schedule objects to make a new tempObj
                        tempObj = {...user, ...schedule}

                        // Push to the array used for rendering
                        t.userScheduleData.push(tempObj)

                    }
                })
            })

            t.filterStaffInToTheirScheduleGroups(t.userScheduleData)
        },

        /**
         * Filter Staff In To Their Schedule Groups
         *
         * Take the merged user/schedule data and filter it into the following arrays for rendering:
         *  - Current Site (current)
         *  - Away from the current site (away)
         *  - Working from Home (home)
         *  - Holiday (holiday)
         *
         * @param userData - user and schedule data (merged) to filter
         */
        filterStaffInToTheirScheduleGroups(userData) {
            const t = this

            t.scheduleTypeCurrentSite = []
            t.scheduleTypeAwayFromSite = []
            t.scheduleTypeWorkingFromHome = []
            t.scheduleTypeHoliday = []
            t.scheduleTypeVisitor = []

            let dayToday = t.$moment().format('dddd')

            // Iterate over the users
            userData.forEach(user => {

                // Iterate over the user object to get the schedule keys and values
                for (const [key, value] of Object.entries(user)) {

                    // If the current day falls on the weekend, show results for monday
                    if (['Saturday', 'Sunday'].includes(dayToday)) {
                        dayToday = 'monday'
                    }

                    // If the user object day matches the current day (is today)
                    // Push the user's to their respective schedule arrays for rendering.
                    // Add the todaysSchedule field for convenience.
                    if (key.toUpperCase() === dayToday.toUpperCase()) {

                        if (user.usualSite.toUpperCase() === t.siteData.siteName.toUpperCase()) {

                            // Current site
                            if (value.toUpperCase() === t.siteData.siteName.toUpperCase()) {
                                user = {...user, todaysSchedule: value, scheduleRenderGroup: 'current'}
                                t.scheduleTypeCurrentSite.push(user)
                            }

                            // Working from Home
                            else if (value.toUpperCase() === 'WORKING FROM HOME') {
                                user = {...user, todaysSchedule: value, scheduleRenderGroup: 'home'}
                                t.scheduleTypeWorkingFromHome.push(user)
                            }

                            // Holiday
                            else if (value.toUpperCase() === 'ANNUAL LEAVE') {
                                user = {...user, todaysSchedule: value, scheduleRenderGroup: 'holiday'}
                                t.scheduleTypeHoliday.push(user)
                            }

                            // Away from site
                            else {
                                user = {...user, todaysSchedule: value, scheduleRenderGroup: 'away'}
                                t.scheduleTypeAwayFromSite.push(user)
                            }

                        }

                        // If the user's usual site is not the current site,
                        // and their schedule has them located here, they're a visitor
                        else if (user.usualSite.toUpperCase() !== t.siteData.siteName.toUpperCase()
                            && value.toUpperCase() === t.siteData.siteName.toUpperCase()) {
                            user = {...user, todaysSchedule: value, scheduleRenderGroup: 'visitor'}
                            t.scheduleTypeVisitor.push(user)
                        }

                    }
                }
            })

            t.sortFilteredStaffScheduleData(t.scheduleTypeCurrentSite)
            t.sortFilteredStaffScheduleData(t.scheduleTypeAwayFromSite)
            t.sortFilteredStaffScheduleData(t.scheduleTypeWorkingFromHome)
            t.sortFilteredStaffScheduleData(t.scheduleTypeHoliday)
        },

        /**
         * Sort Filtered Staff Schedule Data
         *
         * Take the filtered user schedule data and sort if by the following criteria, in order:
         *  - Swapp status ('in' first)
         *  - User name (a-z)
         *
         * @param scheduleType - the filtered array of a given schedule value to sort through
         */
        sortFilteredStaffScheduleData(scheduleType) {
            const t = this

            scheduleType = scheduleType.sort((a, b) => {

                // Only sort the current site by SWAPP status
                if (scheduleType[0].todaysSchedule === t.siteData.siteName) {
                    if (a.swappStatus < b.swappStatus) return 1
                    if (a.swappStatus > b.swappStatus) return -1
                }

                // Sort all in to alphabetical order
                if (a.userName > b.userName) return 1
                if (a.userName < b.userName) return -1
            })
        },

        /**
         * Get Weather Collection Data
         *
         * Fetch all the data from the Weather collection and call to set a five-day forecast.
         *
         * @returns {Promise<void>}
         */
        getWeatherCollectionData: async function () {
            const t = this

            // Get weather collection
            const collection = t.$firebase.db.collection('weather')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.weatherData = []

                // Iterate over the weather and set it to the weatherData
                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    t.weatherData = document
                })

                // Call for a five day forecast
                t.setFiveDayWeatherForecast(t.weatherData)
            })
        },

        /**
         * Set Five Day Weather Forecast
         *
         * Using the raw weather data from the collection, grab the first 5 days
         * and iterate over it to add an icon name from the icon id.
         *
         * @param weatherData - the raw weather data from the collection
         */
        setFiveDayWeatherForecast(weatherData) {
            const t = this
            let fiveDayForecast = []

            // Capture the next five days
            weatherData = weatherData.daily.slice(0, 5)

            // Iterate over the forecast and add the icon names to each day
            weatherData.forEach(day => {
                fiveDayForecast.push({
                    ...day,
                    image_icon: t.getWeatherIconById(day.weather[0].id)
                })
            })

            // Set the forecast for rendering
            t.fiveDayForecast = fiveDayForecast
        },

        /**
         * Get Weather Icon By ID
         *
         * Take the weather icon id, and return an icon name to display.
         *
         * @param id - the weather icon id
         * @return String - the icon name to display
         */
        getWeatherIconById(id) {
            let icon;

            switch (true) {
                case id >= 200 && id < 300:
                    icon = 'icons8-storm';
                    break;
                case id >= 300 && id < 400:
                    icon = 'icons8-light-rain';
                    break;
                case id >= 500 && id < 504:
                    icon = 'icons8-rain';
                    break;
                case id === 511:
                    icon = 'icons8-sleet';
                    break;
                case id >= 511 && id < 531:
                    icon = 'icons8-rain-cloud';
                    break;
                case id >= 600 && id < 700:
                    icon = 'icons8-snow';
                    break;
                case id >= 701 && id < 800:
                    icon = 'icons8-haze';
                    break;
                case id === 800:
                    icon = 'icons8-sun';
                    break;
                case id === 801:
                    icon = 'icons8-partly-cloudy-day';
                    break;
                case id === 802:
                    icon = 'icons8-partly-cloudy-day';
                    break;
                case id === 803:
                    icon = 'icons8-cloud';
                    break;
                case id === 804:
                    icon = 'icons8-cloud';
                    break;

                default:
                    console.log('weather icon id  not recognised: ', id)
            }

            return icon
        },

    },

    /**
     * Mounted
     *
     * Pre-load the required data before components render to the screen.
     *
     * @returns {Promise<void>}
     */
    async mounted() {
        const t = this

        // Decode the URL query string
        await t.decodeUrlQueryString()

        // Get collection data
        await t.getUsersCollectionData()
        await t.getWeatherCollectionData()

        // Start clock timer
        t.interval = setInterval(t.getTime, 1000)

    },

    /**
     * Before Destroy
     *
     * Kill any running processes.
     */
    beforeDestroy() {
        const t = this

        clearInterval(t.interval)
    }
}
</script>

<style>
.dshbrd-cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
    grid-gap: 16px;

    padding: 16px;
}
.dshbrd-cardGrid-small {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
    grid-gap: 16px;

    padding: 16px;
}
</style>
