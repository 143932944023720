<!--
RESET PASSWORD

Renders the reset password form, containing the following:
	- Email (input)
	- Log In (button)
	- Rest Password (button)
-->
<template>
    <v-row class="d-flex justify-center ma-4" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">

            <!--
			Header
			 - Welcome text | Logo
			-->
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

                    <!--Logo-->
                    <div class="d-flex justify-center">
                        <v-img :src="require('@/assets/company_logo.svg')" max-width="128"/>
                    </div>

                    <!--Welcome text-->
                    <app-text category="text-large" class="justify-center mt-4">
                        Welcome to
                        <span class="primary--text font-weight-bold">Scott </span>
                        SWAPP
                    </app-text>

                    <v-divider class="my-4"/>

                </v-col>
            </v-row>

            <!--Title-->
            <!--
             - Screen title and Reset Password instructions
            -->
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

                    <!--Title-->
                    <app-text category="text-large" class="primary--text">Reset Password</app-text>

                    <!--Instruction-->
                    <app-text category="text-default" class="my-4">Please enter your email address to request a password reset.</app-text>

                    <v-divider/>
                </v-col>
            </v-row>

            <!--
            Reset Password Form
                Reset password form with a field for Email, and buttons for Login and Reset Password
            -->
            <v-row class="d-flex justify-center ma-0" no-gutters>
                <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

                    <!--Form-->
                    <firebase-auth-reset />

                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ResetPassword",
};
</script>
