<template>
    <div>
        <v-dialog :value="dialog"
                  max-width="512"
                  @input="closeDialog">

            <!--Dialog content-->
            <v-card>

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text ml-2">SWAPP Issue...</app-text>

                    <v-spacer/>

                    <v-btn @click="closeDialog" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <div class="pa-4">

                    <app-text category="text-default">
                        You are still SWAPPed in to <span class="font-weight-bold">{{ user.lastSite.siteName }}.</span>
                    </app-text>

                    <app-text category="text-default" class="mt-4">
                        We can SWAPP you out of your previous location but please remember, it is very important to <span class="font-weight-bold">SWAPP OUT</span> when you leave.
                        <br><br>
                        In the event of a fire, you will still be shown to be on the previous premises, which could potentially put <span class="font-weight-bold">others at risk</span> if they are looking for you.
                    </app-text>

                    <app-btn @click.native="closeDialog"
                             block
                             class="mt-4"
                             color="error"
                             label="SWAPP Me Out"/>

                </div>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>
export default {

    name: "swappDialog",

    props: ['dialog', 'user'],

    methods: {

        closeDialog() {
            this.$emit('closeDialog')
        }

    }

}
</script>

<style scoped>

</style>
