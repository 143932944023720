<template>
    <v-card
        v-if="$vuetify.breakpoint.width >= 600 || ($vuetify.breakpoint.width < 600 && isGettingStartedMessageVisible)"
        class="rounded-lg pa-4" flat>

        <!--
        Title | Close
        -->
        <div class="d-flex align-center mb-4">

            <!--Title-->
            <app-text category="text-medium-bold" class="primary--text">Getting Started</app-text>

            <v-spacer/>

            <!--Close icon-->
            <v-btn v-if="$vuetify.breakpoint.width < 600" @click="handleHideWelcomeText"
                   icon class="pa-0" color="primary" depressed>
                <v-icon class="icons8-multiply" color="primary"/>
            </v-btn>

        </div>

        <!--Greeting-->
        <app-text category="text-default" class="mb-4">
            Welcome to the {{ $t('global.companyName') }} Safe Workplace App (SWAPP).
            We are just getting started, so you can expect more features soon.
        </app-text>

        <!--Instructions-->
        <app-text category="text-default">
            To begin, SWAPP In at your place of work using the button below,
            and don't forget to SWAPP Out when you leave.
        </app-text>

        <!--Instructions-->
        <app-text v-if="$vuetify.breakpoint.width >= 840" category="text-default-bold" class="mt-4">
            Please note: You must log in via mobile to be able to SWAPP in and out
        </app-text>

    </v-card>
</template>

<script>
export default {

    name: "gettingStartedHomePageCard",

    props: ['isGettingStartedMessageVisible']
}
</script>

<style scoped>

</style>
