<!--
Question

Renders the form to add/edit/delete questions.

As some of the questions can handle multiple entry fields which can't all be displayed in the text field at the same time,
they are displayed as list in card-based components (arrayAnswerCard).
-->
<template>
    <div>

        <!--Header-->
        <v-row class="ma-0 pa-4" no-gutters>
            <app-text category="text-default">New</app-text>
        </v-row>

        <!--Page Content-->
        <v-row no-gutters class="ma-0">
            <v-col xs="6" lg="12">

                <!--Tabs-->
                <v-tabs v-model="tabs" :height="buttonSizeDefault">

                    <!--Overview-->
                    <v-tab href="#overview">
                        <app-text category="text-small">Overview</app-text>
                    </v-tab>

                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tabs" class="appbackground">

                    <!--Overview Tab Content-->
                    <v-tab-item value="overview" class="d-flex flex-column pa-0">

                        <!--Title | Action buttons-->
                        <v-row class="pa-4" no-gutters>

                            <!--Title | Instructions-->
                            <v-col cols="12">

                                <!--Title-->
                                <app-text category="text-default-bold">Overview</app-text>

                                <!--Instructions-->
                                <app-text category="text-default">
                                    To add a new question, please fill out the form below.
                                    Some of the fields will accept multiple entries,
                                    which you can enter by checking the boxes in the fields.
                                </app-text>

                                <v-divider class="my-4"/>

                            </v-col>

                            <!--Action Buttons-->
                            <v-col class="d-flex">

                                <!--Delete-->
                                <v-btn v-if="formMode !== 'New'" @click.native="deleteDocument(form.id)"
                                       class="d-flex align-center mr-4" color="error" depressed
                                       :height="buttonSizeDefault">
                                    <v-icon class="icons8-trash" color="white"/>
                                    <app-text category="text-default" class="white--text ml-2">Delete</app-text>
                                </v-btn>

                                <!--Edit-->
                                <v-btn v-if="formMode !== 'New'" @click.native="editDocument()"
                                       class="d-flex align-center mr-4" color="warning" depressed
                                       :height="buttonSizeDefault">
                                    <v-icon class="icons8-edit-property" color="white"/>
                                    <app-text category="text-default" class="white--text ml-2">Edit</app-text>
                                </v-btn>

                                <v-spacer/>

                                <!--Cancel-->
                                <v-btn v-if="formMode === 'Edit'" @click.native="cancelDocument()"
                                       class="d-flex align-center mr-4" color="grey" depressed
                                       :height="buttonSizeDefault">
                                    <v-icon class="icons8-cancel" color="white"/>
                                    <app-text category="text-default" class="white--text ml-2">Cancel</app-text>
                                </v-btn>

                                <!--Save-->
                                <v-btn v-if="formMode === 'Edit' || formMode === 'New'" @click.native="validateForm"
                                       class="d-flex align-center" color="success" depressed
                                       :height="buttonSizeDefault">
                                    <v-icon class="icons8-save"/>
                                    <app-text category="text-default" class="ml-2">Save</app-text>
                                </v-btn>

                            </v-col>

                        </v-row>

                        <!--Form-->
                        <v-form>
                            <v-row class="px-4" no-gutters>

                                <!--Question Name-->
                                <v-col class="pa-1" cols="12">
                                    <v-text-field
                                        background-color="white"
                                        :error="errors.questionName"
                                        filled
                                        hide-details="auto"
                                        label="Question"
                                        :disabled="formReadOnly"
                                        required
                                        v-model="form.questionName"/>
                                </v-col>

                                <!--Question Description-->
                                <v-col class="pa-1" cols="12">
                                    <v-text-field
                                        background-color="white"
                                        :error="errors.questionDescription"
                                        filled
                                        hide-details="auto"
                                        label="Description"
                                        :disabled="formReadOnly"
                                        required
                                        v-model="form.questionDescription"/>
                                </v-col>

                                <!--Question Answers-->
                                <v-col class="d-flex pa-1" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <v-select background-color="white"
                                              chips
                                              deletable-chips
                                              :error="errors.questionAnswers"
                                              filled
                                              hide-details
                                              :items="answersOptionsData"
                                              label="Answers"
                                              multiple
                                              :disabled="formReadOnly"
                                              required
                                              small-chips
                                              v-model="form.questionAnswers"/>
                                </v-col>

                                <!--Question Correct Answers-->
                                <v-col class="d-flex pa-1" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <v-select background-color="white"
                                              chips
                                              deletable-chips
                                              :error="errors.questionCorrectAnswers"
                                              filled
                                              hide-details
                                              :items="correctAnswersOptionsData"
                                              label="Correct Answers"
                                              multiple
                                              :disabled="formReadOnly"
                                              required
                                              small-chips
                                              v-model="form.questionCorrectAnswers"/>
                                </v-col>

                                <!--Question Position-->
                                <v-col class="pa-1" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <v-text-field
                                        background-color="white"
                                        :error="errors.questionPosition"
                                        filled
                                        hide-details="auto"
                                        label="Position"
                                        :disabled="formReadOnly"
                                        required
                                        v-model="form.questionPosition"/>
                                </v-col>

                                <!--Question Site IDs-->
                                <v-col class="d-flex pa-1" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <v-select
                                        background-color="white"
                                        chips
                                        deletable-chips
                                        :error="errors.questionSiteIDs"
                                        filled
                                        hide-details
                                        :items="sitesLookUp"
                                        item-text="siteName"
                                        label="Sites"
                                        multiple
                                        :disabled="formReadOnly"
                                        required
                                        return-object
                                        small-chips
                                        v-model="form.questionSiteIDs"/>
                                </v-col>

                                <!--Question User Category-->
                                <v-col class="d-flex pa-1" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <v-select background-color="white"
                                              chips
                                              deletable-chips
                                              :error="errors.questionUserCategories"
                                              filled
                                              hide-details
                                              :items="userCategoriesOptionsData"
                                              label="User Categories"
                                              multiple
                                              :disabled="formReadOnly"
                                              required
                                              small-chips
                                              v-model="form.questionUserCategories"/>
                                </v-col>

                                <!--Question Types-->
                                <v-col class="pa-1" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <v-select background-color="white"
                                              chips
                                              deletable-chips
                                              :error="errors.questionTypes"
                                              filled
                                              hide-details
                                              :items="questionTypesOptionsData"
                                              label="Question Types"
                                              multiple
                                              :disabled="formReadOnly"
                                              required
                                              small-chips
                                              v-model="form.questionTypes"/>
                                </v-col>

                            </v-row>
                        </v-form>

                    </v-tab-item>

                </v-tabs-items>

            </v-col>
        </v-row>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import arrayAnswerCard from "../views/questions/arrayAnswerCard/arrayAnswerCard";

export default {
    name: "Questions",

    data: () => ({
        collection: 'questions',
        collectionTitle: 'Questions',
        collectionItem: 'question',
        collectionItemTitle: 'Question',
        errors: {
            questionName: false,
            questionDescription: false,
            questionAnswers: false,
            questionCorrectAnswers: false,
            questionPosition: false,
            questionSiteIDs: false,
            questionUserCategories: false,
            questionTypes: false,
        },
        form: {
            id: '',
            questionName: '',
            questionDescription: '',
            questionAnswers: [],
            questionCorrectAnswers: [],
            questionPosition: '',
            questionSiteIDs: [],
            questionUserCategories: [],
            questionTypes: []
        },
        formMode: 'New',
        formReadOnly: false,
        questionAnswers: [],
        questionCorrectAnswers: [],
        questionSites: [],
        questionUserCategories: [],
        sitesLookUp: [],
        tabs: null,
    }),

    components: {
        arrayAnswerCard,
    },

    computed: ({
        ...mapGetters({
            GET_openItem: 'GET_openQuestion',
            GET_sites: 'GET_sites',
            GET_lookupValues: 'GET_lookupValues',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
        })
    }),

    methods: {
        ...mapActions({ACT_openItem: "ACT_openQuestion", ACT_openQuestion: "ACT_openQuestion"}),

        /**
         * Get Sites Collection Data
         *
         * Fetch the data from the collection.
         * Iterate over the collection and push documents not marked as deleted.
         */
        getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplicates
                t.sitesLookup = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesLookUp.push(document)
                    }

                })
            })
        },

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this

            !t.form.questionName.trim() ? t.errors.questionName = true : t.errors.questionName = false
            !t.form.questionDescription.trim() ? t.errors.questionDescription = true : t.errors.questionDescription = false
            !t.form.questionAnswers.length ? t.errors.questionAnswers = true : t.errors.questionAnswers = false
            !t.form.questionCorrectAnswers.length ? t.errors.questionCorrectAnswers = true : t.errors.questionCorrectAnswers = false
            !t.form.questionPosition.trim() ? t.errors.questionPosition = true : t.errors.questionPosition = false
            !t.form.questionSiteIDs.length ? t.errors.questionSiteIDs = true : t.errors.questionSiteIDs = false
            !t.form.questionUserCategories.length ? t.errors.questionUserCategories = true : t.errors.questionUserCategories = false
            !t.form.questionTypes.length ? t.errors.questionTypes = true : t.errors.questionTypes = false

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        /**
         * Save Questions
         *
         * Save the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            // Create document
            const createDocumentResult = await t.MIX_createDocument(t.collection, t.form)

            if (createDocumentResult.code === 1) {
                t.formMode = 'View'
                t.formReadOnly = true
                await t.refreshDocumentView();
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Edit Document
         *
         * Set the form to be in an editable state where it can be saved.
         */
        editDocument() {
            const t = this

            t.formMode = 'Edit'
            t.formReadOnly = false
        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete ${t.collectionItemTitle}: <br /><strong>${t.form[t.collectionItem + 'Name']}</strong>?`,
                    width: '400',
                    height: '256',
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Cancel Document
         *
         * Set the form back to a readable state and refresh the data.
         */
        cancelDocument() {
            const t = this
            t.formMode = 'View'
            t.formReadOnly = true
            t.refreshDocumentView();
            t.closeRightPanel();
        },

        /**
         * Refresh Document View
         *
         * Load the new document and sets the payload onto ACT_openItem
         */
        async refreshDocumentView() {
            const t = this;
            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            let payload = {}
            payload.process = 'View';
            payload.popup = false;
            payload.data = itemResult.data;

            await t.ACT_openItem(payload);
            // await t.openItem();
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        }
    },

    watch: {
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'questions') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Deleted`, deleteDocumentResult.data, null);
                            await t.refreshDocumentView();
                        } else {
                            t.MIX_alert(-1, `Error Deleting Question`, null, null);
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === 'questions/destroy') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const destroyDocumentResult = await t.MIX_destroyDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (destroyDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Destroyed`, destroyDocumentResult.data, null);
                            t.MIX_setPanelLayout(12, 0, false, '', '');
                        } else {
                            t.MIX_alert(-1, `Error Destroying Question`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    /**
     * Mounted
     *
     * Pre-load the required data before components render to the screen.
     *
     * @returns {Promise<void>}
     */
    async mounted() {
        const t = this

        // Get required  collection data
        await t.getSitesCollectionData()

        // Get and populate form data
        const openItem = await t.GET_openItem
        t.formMode = openItem.process;
        if (openItem.process === 'New') {
            t.formReadOnly = false
        } else {
            t.form = openItem.data
            t.formReadOnly = true
        }

        // if (openItem.popup === false) {
        // 	t.updateBreadcrumbs()
        // }
    }
}
</script>

<style>
.icon-round-right {
    background: white;
    border-radius: 0 50% 50% 0;
}
</style>
