<!--
Sites

Renders the Sites page which displays the details for each site.
This renders as either a table (tablets and desktops) or cards (mobile).

Sites also has a form for adding/editing/deleting site details, and add/viewing new locations.
Each location can also print its own QR code poster enabling personnel to SWAPP in/out.
-->
<template>
    <v-row no-gutters class="fill-height noprint" style="width: 100% !important;">

        <!--
        Left side
         - This is the main part of the page
        -->
        <v-col :cols="GET_panelLayout.leftPanel">

            <!--Body content-->
            <v-row class="pa-4 ma-0" dense>

                <!--Title-->
                <page-title icon="icons8-skyscrapers" pageTitle="Sites"/>

                <!--Search | Action buttons-->
                <v-col cols="12" class="pa-0 mb-4 d-flex align-end">

                    <!--Search-->
                    <v-text-field background-color="white"
                                  filled
                                  hide-details
                                  :height="buttonSizeDefault"
                                  :label="$t(`filters.search`)"
                                  prepend-inner-icon="icons8-search"
                                  v-model="search"/>

                    <v-spacer v-if="$vuetify.breakpoint.width > 664"/>

                    <!--Action buttons-->
                    <div v-if="$vuetify.breakpoint.width > 664" class="d-flex">

                        <!--Add button-->
                        <v-btn v-if="['DA'].includes(GET_currentUser.userLevel)" @click.native="openItem('', 'New', false)"
                               color="primary mr-2" depressed :height="buttonSizeDefault">
                            <v-icon class="icons8-skyscrapers"/>
                            <app-text v-if="$vuetify.breakpoint.width >= 744" category="text-default" class="ml-2">
                                New Site
                            </app-text>
                        </v-btn>

                        <!--Columns button-->
                        <v-btn @click.native="columnsDialog = true"
                               color="primary mr-2" depressed :height="buttonSizeDefault">
                            <v-icon class="icons8-select-column"/>
                            <app-text v-if="$vuetify.breakpoint.width >= 912" category="text-default" class="ml-2">
                                Columns
                            </app-text>
                        </v-btn>

                        <!--Export button-->
                        <v-btn @click.native="MIX_exportDocuments(headersCSV,'Courses',formatExport)"
                               color="primary" depressed :height="buttonSizeDefault">
                            <v-icon class="icons8-export-csv"/>
                            <app-text v-if="$vuetify.breakpoint.width >= 912" category="text-default" class="ml-2">
                                Export
                            </app-text>
                        </v-btn>

                    </div>

                </v-col>

                <!--
                Table
                 - Renders the main table with all the site details (medium sized-screens and up)
                -->
                <v-col v-if="$vuetify.breakpoint.width > 664"
                       class="pa-0 tabledata" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <v-data-table
                        :headers="computedHeaders"
                        :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
                        :items="sortedTableData"
                        item-key="id"
                        class="elevation-0"
                        hide-default-header
                        :search="search">

                        <!-- Headers -->
                        <template v-slot:header="{ props }">
                            <th v-for="(head, index) in props.headers" :key="index" :align="head.align" class="pa-4">
                                <app-text category="text-small" class="grey--text">{{ head.text }}</app-text>
                            </th>
                        </template>

                        <!--Site name-->
                        <template v-slot:[`item.siteName`]="{ item }">
                            <app-text category="text-small">{{ item.siteName }}</app-text>
                        </template>

                        <!--Telephone-->
                        <template v-slot:[`item.siteTelephone`]="{ item }">
                            <app-text category="text-small">{{ item.siteTelephone }}</app-text>
                        </template>

                        <!--Contact name-->
                        <template v-slot:[`item.siteContact.contactName`]="{ item }">
                            <sites-manager-selection-modal :site="item"
                                                           :users="usersData"
                                                           v-on:updateManager="updateWithNewManager"/>
                        </template>

                        <!--Contact position-->
                        <template v-slot:[`item.siteContact.contactPosition`]="{ item }">
                            <app-text category="text-small">{{ item.siteContact.contactPosition }}</app-text>
                        </template>

                        <!--Contact telephone-->
                        <template v-slot:[`item.siteContact.contactTelephone`]="{ item }">
                            <app-text category="text-small">{{ item.siteContact.contactTelephone }}</app-text>
                        </template>

                        <!--Contact mobile-->
                        <template v-slot:[`item.siteContact.contactMobile`]="{ item }">
                            <app-text category="text-small">{{ item.siteContact.contactMobile }}</app-text>
                        </template>

                        <!--Contact email-->
                        <template v-slot:[`item.siteContact.contactEmail`]="{ item }">
                            <app-text category="text-small">{{ item.siteContact.contactEmail }}</app-text>
                        </template>

                        <!--Action button-->
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn @click="openItem(item.id, 'View', false)"
                                   depressed class="primary--text white">Open
                                <v-icon class="icons8-forward" color="primary"/>
                            </v-btn>
                        </template>

                    </v-data-table>

                </v-col>

                <!--
                Mobile Cards
                 - Renders cards instead of the table (small sized-screens and down)
                -->
                <v-col v-if="$vuetify.breakpoint.width <= 664"
                       class="pa-0 tabledata" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <div v-for="site in filteredSites" :key="site.id">

                        <v-card @click="openItem(site.id, 'View', false)" class="mb-4 pa-4" flat>

                            <!--Site name-->
                            <app-text category="text-default-bold">{{ site.siteName }}</app-text>

                            <!--Site address-->
                            <app-text category="text-default">{{ computedSiteAddress(site) }}</app-text>

                        </v-card>

                    </div>

                </v-col>

            </v-row>

        </v-col>

        <!-- Right panel -->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animated__faster"
                    leave-active-class="animate__animated animate__fadeOut animated__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
            <!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
        </transition>

        <!-- Column selection box -->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card>
                <!--Title-->
                <app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers" :key="index">
                            <app-text category="text-default">
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false" :label="header.text"/>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn @click="columnsDialog = false" color="primary" text>Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import sitesManagerSelectionModal from './sitesManagerSelectionModal/sitesManagerSelectionModal'

export default {
    name: "Sites",

    components: {
        sitesManagerSelectionModal
    },

    data: () => ({
        title: 'Sites',
        collection: 'sites',
        collectionItem: 'sites',
        collectionItemTitle: 'Site',
        columnsDialog: false,
        customWhere: [],
        exportSites: [],
        headers: [
            // {text: 'Client', value: 'clientData.clientName', align: 'start', sortable: false, hidden: false, hide: false},
            {text: 'Name', value: 'siteName', align: 'start', sortable: false, hidden: false, hide: false},
            {text: 'Telephone', value: 'siteTelephone', align: 'start', sortable: false, hidden: false, hide: false,},
            {
                text: 'Address Line 1',
                value: 'siteAddress.addressLine1',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Address Line 2',
                value: 'siteAddress.addressLine2',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {
                text: 'Address Line 3',
                value: 'siteAddress.addressLine3',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            {text: 'Town', value: 'siteAddress.town', align: 'start', sortable: false, hidden: true, hide: false},
            {text: 'County', value: 'siteAddress.county', align: 'start', sortable: false, hidden: true, hide: false},
            {
                text: 'Postcode',
                value: 'siteAddress.postcode',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false
            },
            // {text: 'Area', value: 'siteArea', align: 'start', sortable: false, hidden: false, hide: false,},
            // {text: 'Commercial Manager', value: 'siteCommercialManager', align: 'start', sortable: false,hidden: false,hide: false,},
            // {text: 'Site Manager', value: 'siteManager', align: 'start', sortable: false, hidden: false, hide: false,},
            {
                text: 'Site Contact Name',
                value: 'siteContact.contactName',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site Contact Position',
                value: 'siteContact.contactPosition',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site Contact Telephone',
                value: 'siteContact.contactTelephone',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site Contact Mobile',
                value: 'siteContact.contactMobile',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site Contact Email',
                value: 'siteContact.contactEmail',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false,
            },
            // {text: 'PPE Require', value: 'ppeRequired', align: 'center', sortable: false, hidden: false, hide: false,},
            // {text: 'DBS Check', value: 'dbsCheck', align: 'center', sortable: false, hidden: false, hide: false,},
            // {text: 'Sector', value: 'siteSector', align: 'start', sortable: false, hidden: false, hide: false,},
            // {text: 'Notes', value: 'siteNotes', align: 'start', sortable: false, hidden: false, hide: false,},
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                hidden: false,
                hide: false,
                width: '100px'
            },
        ],
        headersCSV: {
            clientName: 'Client Name',
            siteName: 'Site Name',
            siteTelephone: 'Site Telephone',
            siteAddress: 'Site Address',
            sitePostcode: 'Site Postcode',
            siteArea: 'Site Area',
            siteCommercialManager: 'Commercial Manager',
            siteManager: 'Site Manager',
            siteContactName: 'Site Contact Name',
            siteContactPosition: 'Site Contact Position',
            siteContactTelephone: 'Site Contact Telephone',
            siteContactMobile: 'Site Contact Mobile',
            siteContactEmail: 'Site Contact Email',
            ppeRequired: 'PPE Required',
            dbsCheck: 'DBS Check',
            siteSector: 'Site Sector',
            siteNotes: 'Site Notes'
        },
        search: '', // Table search,
        tableData: [],
        usersData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
            GET_currentUser: 'GET_currentUser',
        }),

        formatExport() {
            const t = this
            t.exportSites = []

            var sites = JSON.parse(JSON.stringify(this.tableData))
            for (let i = 0; i < sites.length; i++) {
                var clientName = "";
                if (sites[i].clientData.clientName) {
                    clientName = sites[i].clientData.clientName
                } else {
                    clientName = "UNKNOWN"
                }
                var siteName = "";
                if (sites[i].siteName) {
                    siteName = sites[i].siteName
                } else {
                    siteName = "UNKNOWN"
                }
                var siteTelephone = "";
                if (sites[i].siteTelephone) {
                    siteTelephone = sites[i].siteTelephone
                } else {
                    siteTelephone = "UNKNOWN"
                }
                var siteAddress = "";
                if (sites[i].siteAddress.addressLine1) {
                    siteAddress = sites[i].siteAddress.addressLine1
                } else {
                    siteAddress = "UNKNOWN"
                }
                var sitePostcode = "";
                if (sites[i].siteAddress.postcode) {
                    sitePostcode = sites[i].siteAddress.postcode
                } else {
                    sitePostcode = "UNKNOWN"
                }
                var siteArea = "";
                if (sites[i].siteArea) {
                    siteArea = sites[i].siteArea
                } else {
                    siteArea = "UNKNOWN"
                }
                var siteCommercialManager = "";
                if (sites[i].siteCommercialManager) {
                    siteCommercialManager = sites[i].siteCommercialManager
                } else {
                    siteCommercialManager = "UNKNOWN"
                }
                var siteManager = "";
                if (sites[i].siteManager) {
                    siteManager = sites[i].siteManager
                } else {
                    siteManager = "UNKNOWN"
                }
                var siteContactName = "";
                if (sites[i].siteContact.contactName) {
                    siteContactName = sites[i].siteContact.contactName
                } else {
                    siteContactName = "UNKNOWN"
                }
                var siteContactPosition = "";
                if (sites[i].siteContact.contactPosition) {
                    siteContactPosition = sites[i].siteContact.contactPosition
                } else {
                    siteContactPosition = "UNKNOWN"
                }
                var siteContactTelephone = "";
                if (sites[i].siteContact.contactTelephone) {
                    siteContactTelephone = sites[i].siteContact.contactTelephone
                } else {
                    siteContactTelephone = "UNKNOWN"
                }
                var siteContactMobile = "";
                if (sites[i].siteContact.contactMobile) {
                    siteContactMobile = sites[i].siteContact.contactMobile
                } else {
                    siteContactMobile = "UNKNOWN"
                }
                var siteContactEmail = "";
                if (sites[i].siteContact.contactEmail) {
                    siteContactEmail = sites[i].siteContact.contactEmail
                } else {
                    siteContactEmail = "UNKNOWN"
                }
                var ppeRequired = "";
                if (sites[i].ppeRequired) {
                    ppeRequired = sites[i].ppeRequired
                } else {
                    ppeRequired = "UNKNOWN"
                }
                var dbsCheck = "";
                if (sites[i].dbsCheck) {
                    dbsCheck = sites[i].dbsCheck
                } else {
                    dbsCheck = "UNKNOWN"
                }
                var siteSector = "";
                if (sites[i].siteSector) {
                    siteSector = sites[i].siteSector
                } else {
                    siteSector = "UNKNOWN"
                }
                var siteNotes = "";
                if (sites[i].siteNotes) {
                    siteNotes = sites[i].siteNotes
                } else {
                    siteNotes = "UNKNOWN"
                }
                this.exportSites.push({
                    clientName: clientName,
                    siteName: siteName,
                    siteTelephone: siteTelephone,
                    siteAddress: siteAddress,
                    sitePostcode: sitePostcode,
                    siteArea: siteArea,
                    siteCommercialManager: siteCommercialManager,
                    siteManager: siteManager,
                    siteContactName: siteContactName,
                    siteContactPosition: siteContactPosition,
                    siteContactTelephone: siteContactTelephone,
                    siteContactMobile: siteContactMobile,
                    siteContactEmail: siteContactEmail,
                    ppeRequired: ppeRequired,
                    dbsCheck: dbsCheck,
                    siteSector: siteSector,
                    siteNotes: siteNotes
                })
            }
            return this.exportSites;
        },

        computedCols() {
            const t = this
            let cols
            let leftPanel = this.GET_panelLayout.leftPanel

            switch (true) {
                case (leftPanel === 12):
                    cols = 6
                    break
                case (leftPanel === 6):
                    cols = 12
                    break;
                default:
                    cols = 12
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                cols = 12
            }

            return cols
        },

        computedHeaders() {
            const t = this
            const headers = this.headers

            // headers.forEach(function(header) {
            //     header.text = t.$t(`fields.${header.text}`)
            // })

            return (this.$filter(headers, {
                hidden: false, // filter headers based on initial hidden value
            }))
        },

        /**
         * Sorted Table Data
         *
         * Sort the sites array in to alphabetical order, A-Z.
         *
         * @returns array - array containing the alphabetised list of sites
         */
        sortedTableData() {
            const t = this

            return t.tableData.sort((a, b) => {
                if (a.siteName > b.siteName) {
                    return 1
                }
                if (a.siteName < b.siteName) {
                    return -1
                }
            })
        },

        /**
         * Filtered Sites
         *
         * Take the sortedTableData and return an array of sites filtered by the search string.
         *
         * @returns array - sites filtered by search string, ordered alphabetically
         */
        filteredSites() {
            const t = this

            return t.sortedTableData.filter(site => site.siteName.toLowerCase().match(t.search.toLowerCase()))
        }

    },

    methods: {
        ...mapActions({'ACT_openItem': 'ACT_openSites', 'ACT_openSites': 'ACT_openSites'}),

        /**
         * Get Users Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.usersData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.usersData.push(document)
                    }
                })
            })
        },

        async getSitesCollectionData() {
            const t = this

            let collection = t.$firebase.db.collection('sites')

            if (JSON.stringify(t.customWhere) !== '[]') {

                for (let i = 0; i < t.customWhere.length; i++) {
                    const query = {
                        field: t.customWhere[i].key,
                        operator: t.customWhere[i].operator,
                        value: t.customWhere[i].value
                    }

                    collection = collection.where(query.field, query.operator, query.value)
                }
            }

            collection.onSnapshot(function (querySnapshot) {

                // Clear the array to avoid data duplication
                t.tableData = []

                querySnapshot.forEach(function (doc) {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    } else if (t.showDeleted) {
                        t.tableData.push(document)
                    } else {
                        // Do not Show Concern
                    }

                })
            })

        },

        async openItem(id, process, popup) {
            const t = this
            const item = {}

            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById('sites', id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)

                // * HIDE HEADERS
                for (let i = 0; i < t.headers.length; i++) {
                    if (t.headers[i].hide === true) {
                        t.headers[i].hidden = true
                    }
                }
            }

        },

        /**
         * Update With New Manager
         *
         * Update the site details with the new site manager's: name, position, telephone and email.
         *
         * @returns {Promise<void>}
         */
        async updateWithNewManager(payload) {
            const t = this
            const {manager, site} = payload

            // Add new values to existing document
            const form = {
                ...site,
                siteContact: {
                    contactName: manager.userName,
                    contactPosition: manager.userJobTitle,
                    contactTelephone: manager.userTelephone,
                    contactEmail: manager.userEmail,
                }
            }

            // Update the document
            const updateDocumentResult = await this.MIX_updateDocument(t.collection, form)

            // Call for the confirmation box
            t.renderConfirmationAlert(updateDocumentResult,
                'Site manager successfully updated',
                'Error updating site manager')
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document, successMessage, failureMessage) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, successMessage, null, null)
            } else {
                t.MIX_alert(-1, failureMessage, null, null)
            }
        },

        computedSiteAddress(site) {
            let address = ''

            if (site.siteAddress.addressLine1) {
                address += site.siteAddress.addressLine1 + ', '
            }

            if (site.siteAddress.town) {
                address += site.siteAddress.town + ', '
            }

            if (site.siteAddress.postcode) {
                address += site.siteAddress.postcode
            }

            return address

        },

    },

    /**
     * Mounted
     *
     * Pre-load the required data before components render to the screen.
     *
     * @returns {Promise<void>}
     */
    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
        ////t.updateBreadcrumbs(); // Update Breadcrumbs in Title
        // INSERT CUSTOM WHERES HERE
        // t.customWhere.push ({ key: '', operator: '==', value: '' });
        t.getSitesCollectionData(); // Get Table Data

        await t.getUsersCollectionData()
    }
}
</script>
