import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyDZxz1HAVFDSIgBmdqB0Iay9477pYEwjRo",
  authDomain: "scott-swapp.firebaseapp.com",
  projectId: "scott-swapp",
  storageBucket: "scott-swapp.appspot.com",
  messagingSenderId: "856763637879",
  appId: "1:856763637879:web:9694e84aa00389a592e672",
  measurementId: "G-0P5TFBZBME"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const fv = firebase.firestore.FieldValue;

export {
  db,
  storage,
  auth,
  currentUser,
  fv
};