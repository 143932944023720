<template>
    <div>
        <v-dialog v-model="dialog"
                  transition="dialog-bottom-transition"
                  max-width="512">

            <!--Activator-->
            <template v-slot:activator="{on, attrs}">

                <div v-on="on" :style="$vuetify.breakpoint.width < 600 && 'width: calc(100vw - 32px)'">
                    <app-btn
                        :block="$vuetify.breakpoint.width < 600"
                        color="primary"
                        icon="icons8-fires"
                        label="Start Fire Roll Call"/>
                </div>

            </template>

            <!--Dialog content-->
            <v-card>

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text ml-2">Start Fire Roll Call</app-text>

                    <v-spacer/>

                    <v-btn @click="dialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <!--Body-->
                <div class="pa-4">

                    <!--Info text-->
                    <app-text category="text-default-bold">You are about to start a Fire Roll Call</app-text>

                    <!--Instructions-->
                    <ul class="mt-4">
                        <li><app-text category="text-default" class="mt-4">Check off each person as you go</app-text></li>
                        <li><app-text category="text-default" class="mt-4">Tap a person's image to call them</app-text></li>
                        <li><app-text category="text-default" class="mt-4">Complete the form when you're finished</app-text></li>
                        <li><app-text category="text-default" class="mt-4">The timer will stop when you submit the form</app-text></li>
                        <li><app-text category="text-default" class="mt-4">You can cancel at any time</app-text></li>
                    </ul>

                    <!--Action buttons-->
                    <app-btn @click.native="handleCancelRegister"
                             block="block"
                             class="mt-4"
                             color="success"
                             label="Continue"/>

                </div>

            </v-card>


        </v-dialog>
    </div>
</template>

<script>
export default {

    name: "fireRollCallCancelDialog",

    data: () => ({

        dialog: false,

    }),

    methods: {

        handleCancelRegister() {
            const t = this

            t.$emit('emitHandleStartRegister')
            t.dialog = false
        }

    }
}
</script>

<style>

</style>
