<!--
User Schedule

Renders a schedule for the user to select where they're going to be Mon-Fri.
To make entering the same value for every day simpler, the user can also use the Set All button/dialog.
-->
<template>
    <v-row no-gutters class="ma-4">

        <!--Title-->
        <page-title icon="icons8-schedule" pageTitle="My Schedule"/>

        <!--Instructional text-->
        <v-col cols="12">
            <app-text category="text-default">
                Use this scheduler to set your expected location.
                Any time your schedule changes (e.g. changing site, holidays etc) please update this form.
            </app-text>
        </v-col>

        <!--Divider-->
        <v-col cols="12">
            <v-divider class="my-4"/>
        </v-col>

        <!--Missing fields | Set All button/dialog-->
        <v-col class="d-flex" cols="12">

            <!--Missing fields-->
            <app-text v-if="scheduleError" category="text-default-bold" class="red--text">{{ scheduleError }}</app-text>

            <v-spacer/>

            <!--Set All button/dialog-->
            <user-schedule-set-all-dialog v-on:updateUserScheduleSetAll="updateUserScheduleSetAll"
                                          :scheduleOptions="scheduleOptions"/>
        </v-col>

        <!--Days form-->
        <v-form style="width: 100%">

            <!--Day fields-->
            <v-col class="pa-0" cols="12">

                <!--Monday-->
                <form-field-container>
                    <v-select background-color="grey lighten-3"
                              :error="errors.monday"
                              filled
                              hide-details="auto"
                              :items="scheduleOptions"
                              item-text="siteName"
                              label="Monday"
                              required
                              v-model="form.monday">
                    </v-select>
                </form-field-container>

                <!--Tuesday-->
                <form-field-container>
                    <v-select background-color="grey lighten-3"
                              :error="errors.tuesday"
                              filled
                              hide-details="auto"
                              :items="scheduleOptions"
                              item-text="siteName"
                              label="Tuesday"
                              required
                              v-model="form.tuesday">
                    </v-select>
                </form-field-container>

                <!--Wednesday-->
                <form-field-container>
                    <v-select background-color="grey lighten-3"
                              :error="errors.wednesday"
                              filled
                              hide-details="auto"
                              :items="scheduleOptions"
                              item-text="siteName"
                              label="Wednesday"
                              required
                              v-model="form.wednesday">
                    </v-select>
                </form-field-container>

                <!--Thursday-->
                <form-field-container>
                    <v-select background-color="grey lighten-3"
                              :error="errors.thursday"
                              filled
                              hide-details="auto"
                              :items="scheduleOptions"
                              item-text="siteName"
                              label="Thursday"
                              required
                              v-model="form.thursday">
                    </v-select>
                </form-field-container>

                <!--Friday-->
                <form-field-container>
                    <v-select background-color="grey lighten-3"
                              :error="errors.friday"
                              filled
                              hide-details="auto"
                              :items="scheduleOptions"
                              item-text="siteName"
                              label="Friday"
                              required
                              v-model="form.friday">
                    </v-select>
                </form-field-container>

            </v-col>

        </v-form>

        <!--Action buttons-->
        <v-col class="d-flex mt-4">

            <v-spacer/>

            <!--Submit-->
            <v-btn @click="validate" color="success" depressed :height="buttonSizeDefault">
                <v-icon class="icons8-checkmark-yes"/>
                <app-text category="text-default" class="ml-2">Submit</app-text>
            </v-btn>

        </v-col>

    </v-row>
</template>

<script>
import formFieldContainer from '../../components/commonComponents/formFieldContainer'
import userScheduleSetAllDialog from "./userScheduleSetAllDialog/userScheduleSetAllDialog"
import {mapGetters} from "vuex";

export default {
    components: {
        formFieldContainer,
        userScheduleSetAllDialog
    },

    data: () => ({
        form: {
            id: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
        },
        errors: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
        },
        scheduleError: '',
        scheduleOptions: [],
        sitesData: [],
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
            GET_lookupValues: 'GET_lookupValues',
        }),
    },

    methods: {
        /**
         * Get Schedule Collection
         *
         * Fetch Schedule collection data.
         * Assign document day values to form day values.
         * If the user has no schedule, render an error.
         *
         * @returns {Promise<void>}
         */
        async getScheduleCollectionData() {
            const t = this
            let mySchedule = {}

            const collection = t.$firebase.db.collection('schedule').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            if (doc.exists) {
                mySchedule = doc.data()
            } else {
                t.scheduleError = 'You must complete your schedule'
            }

            t.form.monday = mySchedule.monday
            t.form.tuesday = mySchedule.tuesday
            t.form.wednesday = mySchedule.wednesday
            t.form.thursday = mySchedule.thursday
            t.form.friday = mySchedule.friday
        },

        /**
         * Get Sites Collection
         *
         * Clear the data to avoid duplications.
         * Fetch all the data from the Sites collection.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                t.sitesData = []

                // Iterate over the collection and add the records to the array
                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document.siteName)
                    }
                })

                // Sort sites in to alphabetical order
                t.sitesData.sort()

                t.mergeSiteAndScheduleOptions(t.sitesData)

            })
        },

        /**
         * Merge Site and Schedule Options
         *
         * Take the sites data and the schedule lookup values and create a new new array
         * of just the text-based values to use in the select drop-downs.
         */
        mergeSiteAndScheduleOptions(sitesData) {
            const t = this

            t.scheduleOptions = [...t.extendedScheduleOptionsData, ...sitesData]
        },

        /**
         * Update UserSchedule Set All
         *
         * @param payload - the selected option returned from userScheduleSetAllDialog.vue.
         */
        updateUserScheduleSetAll(payload) {
            const t = this

            t.form.monday = payload
            t.form.tuesday = payload
            t.form.wednesday = payload
            t.form.thursday = payload
            t.form.friday = payload
        },

        /**
         * Validate
         *
         * Validate all fields for presence.
         * If all days are filled call to saveDocument().
         * If any fields are missing, render an error.
         */
        validate() {
            const t = this

            !t.form.monday ? t.errors.monday = true : t.errors.monday = false
            !t.form.tuesday ? t.errors.tuesday = true : t.errors.tuesday = false
            !t.form.wednesday ? t.errors.wednesday = true : t.errors.wednesday = false
            !t.form.thursday ? t.errors.thursday = true : t.errors.thursday = false
            !t.form.friday ? t.errors.friday = true : t.errors.friday = false

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Save the current document to the database.
         * If there was a schedule error, clear it.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            // Create document
            const createDocumentResult = await t.MIX_updateDocument('schedule', t.form)

            if (createDocumentResult.code === 1) {
                t.scheduleError = ''
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `Schedule saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving Schedule`, null, null)
            }
        }
    },

    async mounted() {
        const t = this

        // Use the user ID as the form ID
        t.form.id = t.GET_currentUser.id

        // Fetch collection data
        await t.getScheduleCollectionData()
        await t.getSitesCollectionData()
    }
}
</script>
